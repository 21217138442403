<template>
  <div class="page-news">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3>Мэдээ, мэдээлэл</h3>
          <span class="divider"></span>
        </div>
        <div class="header-breadcrumb">
          <router-link to="/">
            <span>Эхлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link to="" class="current">
            <span>Мэдээ, мэдээлэл</span>
          </router-link>
        </div>
      </div>
      <div>
      </div>

    </div>
    <div v-if="news.length > 0">
      <div class="news-section">
        <div class="grid sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-4">
          <div class="news-card" v-for="item in news" :key="item.id">
            <div class="img">
              <img :src="imageUrl(item.thumb)" :alt="image"
                   class="news-img"/>
            </div>
            <div class="news-content">
              <div class="news-title">
                <h1>{{ item.title }}</h1>
              </div>
              <div class="news-date">
                <p>{{ formatDate(item.created_at) }}</p>
                <router-link :to="`/news/detail/${item.id}`" class="media-cover">
                  <button><p style="color:green;display:flex; flex-direction:row; align-items:center">
                    Дэлгэрэнгүй <i
                      style="margin-left:10px" class="isax isax-arrow-right-1"></i></p></button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display:flex; justify-content:center">
      <button @click="prevPage" :disabled="currentPage === 1">Өмнөх</button>
      <span style="margin-left:20px; margin-right:20px">{{ currentPage }}</span>
      <button :style="{ color: lastPageColor }" @click="nextPage" :disabled="currentPage === totalPages">Дараах</button>
    </div>
  </div>
  <div>

  </div>
</template>

<script>
import axios from "axios";
import config from "@store/_boot/config";
import {imageUrl} from "@utils";
import moment from 'moment';


export default {
  data() {
    return {
      isLoading: true,
      isLoadingMore: false,
      hasMore: false,
      page: 1,
      news: [],
      newsPerPage: '',
      totalNews: 0,
      currentPage: 1,
      lastPage: 0,
    };
  },
  created() {
    this.getNews();
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalNews / this.newsPerPage);
    },
    lastPageColor() {
      return this.lastPage === this.currentPage ? 'grey' : '';
    },

  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    imageUrl,
    getNews() {
      axios.get(`${config.HOST}/api/footer/get-news?page=${this.currentPage}`).then(({data}) => {
        if (data.status) {
          this.news = data.data.data;
          this.totalNews = data.data.total;
          this.currentPage = data.data.current_page;
          this.newsPerPage = data.data.per_page;
          this.lastPage = data.data.last_page;
          console.log("news----->", this.news)
          console.log(" this.totalNews----->", this.totalNews)
          console.log(" this.currentPage----->", this.currentPage)
          console.log("this.newsPerPage----->", this.newsPerPage)
        }
      })
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        console.log("dsvssdvsdv------>")
        this.currentPage++;
        this.getNews();
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.getNews();
      }
    }
  },

};
</script>

<style>
.news-section {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: transparent;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding-top: 40px;

  .news-card {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(128, 128, 128, 0.3);
    margin: 20px;

    .img {
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

      .news-img {
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        height: 220px;
        width: 100%;
      }
    }

    .news-content {
      padding: 10px 20px 10px 20px;

      .news-title {
        height: 40px;

        h1 {
          font-weight: bold;
          width: auto;
          overflow: hidden;
          max-height: 40px;
          line-height: 20px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
      }

      .news-date {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }
  }


}
</style>

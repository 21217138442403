import api from "../_boot/baseApi";

export const getPaymentOptions = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get('/api/m/payment/options/list').then(({data}) => {
        commit('setPaymentOptions', data)
        payload.vm.isLoading = false;
    })
}

// export const createQpayInvoice = ({commit}, payload) => {
//     payload.vm.isProceeding = true;
//     payload.vm.btnTxt = "Түр хүлээнэ үү";
//     payload.vm.count = 90;
//
//     axios.get(`/api/qpay/invoice/${payload.vm.$user.id}/${payload.vm.selectedCard}`).then(({data}) => {
//         if (data.status) {
//             payload.vm.qpayInvoice = data.data;
//
//             setTimeout(() => {
//                 payload.vm.step++;
//                 payload.vm.isProceeding = false;
//                 payload.vm.btnTxt = "Үргэлжлүүлэх";
//                 // payload.vm.countTimer = setInterval(() => {
//                 //     payload.vm.count--;
//                 //     if (this.count === 0) {
//                 //         payload.vm.cancelQpay();
//                 //         // payload.vm.$notify.error({
//                 //         //     title: 'Хугацаа дууслаа',
//                 //         //     message: 'Та төлбөр төлөх хүсэлтээ дахин илгээнэ үү'
//                 //         // });
//                 //     }
//                 // }, 1000);
//                 // this.checkPayment(data.bill);
//             }, 1000);
//         } else {
//             setTimeout(() => {
//                 payload.vm.isProceeding = false;
//                 // this.$notify.error({
//                 //     title: 'Алдаа гарлаа',
//                 //     message: data.msg,
//                 // });
//             }, 1000);
//         }
//     }).catch(e => {
//         setTimeout(() => {
//             payload.vm.isProceeding = false;
//             // this.$Message.error("Алдаа гарлаа");
//             // this.$notify.error({
//             //     title: 'Алдаа гарлаа',
//             //     message: "",
//             // });
//         }, 1000);
//     })
// }
//
// export const checkPayment = ({commit}, bill) => {
//     axios.get(`/api/qpay/check/${bill}`).then(({data}) => {
//         if (data.status == 1) {
//             this.gotoFinal();
//             this.isSuccess = true;
//             this.resultMsg = data.msg;
//         }
//
//         if (data.status == 0) {
//             this.gotoFinal();
//             this.isSuccess = false;
//             this.resultMsg = data.msg;
//         }
//
//         if (data.status == 2 && this.step == 2) {
//             setTimeout(() => {
//                 this.checkPayment(bill);
//             }, 2000)
//         }
//     }).catch(e => {
//         this.cancelQpay();
//         this.$notify.error({
//             title: 'Алдаа гарлаа',
//             message: data.msg
//         });
//     })
// }
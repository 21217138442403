<template>
  <div class="notification">
    <div tabindex="0" class="m-1 user-control">
      <Button icon="pi pi-bell" class="p-button-rounded p-button p-button-outlined" badgeClass="p-badge-danger" @click="toggle" />
    </div>

    <OverlayPanel ref="op" :showCloseIcon="true" style="width: 300px">
      <h1>Notif here</h1>
    </OverlayPanel>
  </div>
</template>

<script>
import {mapGetters} from "vuex";


export default {
  components: {},
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    }
  }
};
</script>
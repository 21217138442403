<template>
<!--  <div v-if="isLoadingAuth">loading</div>-->

  <div class="header-profile flex-none" >
<!--  <div class="header-profile flex-none">-->
    <Button v-if="user == null" label="Нэвтрэх" class="p-button-sm tail-red-button mt-1 ml-1" @click="showAuthModal"/>

    <div v-else class="user-control m-1 flex items-center" @click="openUserMenu">
      <Image
          :src="user.image"
          class="h-10 w-10 rounded-full object-cover border avatar"/>
      <div class="auth-info ml-2">
        <span class="hidden lg:flex">{{ user.nickname }}</span>
      </div>
    </div>
    <Sidebar v-model:visible="visibleUserMenu" :hideUserMenu="hideUserMenu"/>
  </div>

  <Dialog :showHeader="false" class="login-page-modal" v-model:visible="isAuthVisible" :modal="true" :dismissableMask="true"
          position="center" :closable="true" :style="{width: '600px'}">
    <Login :hide="hideAuthModal"/>
  </Dialog>
</template>

<script>
import Login from "@pages/auth/login.vue";
import Sidebar from "@components/AuthSidebar.vue";
import Image from "./Image.vue";
import {mapActions, mapGetters} from 'vuex';

export default {
  components: {
    Login,
    Image,
    Sidebar
  },

  async created() {
    await this.getUser();
    this.$store.watch(() => this.$store.getters['isLoadingAuth'], (value) => {
      console.log('state changed to', value);
    });
  },

  data() {
    return {
      isAuthVisible: false,
      visibleUserMenu: false,
    }
  },

  computed: {
    ...mapGetters(['isLoadingAuth', 'user', 'episodes'])
  },
  methods: {
    ...mapActions(['getUser']),

    showAuthModal() {
      this.isAuthVisible = true;
    },
    hideAuthModal() {
      this.isAuthVisible = false;
    },

    closeModal() {
      this.visibleUserMenu = false;
    },

    openUserMenu() {
      this.visibleUserMenu = true;
    },

    hideUserMenu() {
      this.visibleUserMenu = false;
    },
  }
};
</script>
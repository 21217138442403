export const setMedia = (state, payload) => {
    state.media = payload;
};

export const setLatestMovies = (state, payload) => {
    state.latestMovies = payload;
};

export const setHomeCatMedias = (state, payload) => {
    state.homeCatMedias = payload;
};

export const setCats = (state, payload) => {
    state.cats = payload;
};

export const setSeasons = (state, payload) => {
    state.seasons = payload;
};

export const setMediaList = (state, payload) => {
    state.mediaList = payload;
    console.log("data---------->", state.mediaList);
}

export const setLikedMediaList = (state, payload) => {
    state.likedMediaList = payload;
    console.log("data---------->", state.likedMediaList);
}

export const setSeasonMedias = (state, payload) => {
    state.seasonMedias = payload;
}

export const setSuggestedMedias = (state, payload) => {
    state.suggestedMedias = payload;
}

export const setRelatedMedias = (state, payload) => {
    state.relatedMedias = payload;
}

export const setStatus = (state, payload) => {
    if (payload.action == "like") {
        state.media.liked = payload.value;
    }

    if (payload.action == "later") {
        state.media.later = payload.value;
    }

    if (payload.action == "notify") {
        state.media.notify = payload.value;
    }

    if (payload.action == "status") {
        state.media.watch_status = payload.value;
    }
}

export const clearMedia = (state) => {
    state.media = null;
}
<template>
  <div
      class="header seeroo top-0 fixed z-50 w-full h-16 transition duration-700 header__overlay"
      :class="[isFixed && 'bg-background-header']">
    <div class="relative z-10 flex items-center justify-between w-full h-full">
      <div class="header-left">
        <router-link to="/" class="nav-logo">
          <img src="/img/logo-seeroo.png" alt="logo" class="large-logo"/>
        </router-link>
        <nav class="header-nav">
          <router-link to="/">
            <img src="/img/menu-icon/active/homepage.png" alt="logo" class="header-icon active"/>
            <img src="/img/menu-icon/inactive/homepage.png" alt="logo" class="header-icon inactive"/>
            <span>Нүүр</span>
          </router-link>
          <router-link to="/series">
            <img src="/img/menu-icon/active/genre.png" alt="logo" class="header-icon active"/>
            <img src="/img/menu-icon/inactive/genre.png" alt="logo" class="header-icon inactive"/>
            <span>Жанр</span>
          </router-link>
          <router-link to="/character">
            <img src="/img/menu-icon/active/character.png"  alt="logo" class="header-icon active"/>
            <img src="/img/menu-icon/inactive/character.png"  alt="logo" class="header-icon inactive"/>
            <span>Дүр</span>
          </router-link>
          <router-link to="/age">
            <img src="/img/menu-icon/active/age.png"  alt="logo" class="header-icon active"/>
            <img src="/img/menu-icon/inactive/age.png"  alt="logo" class="header-icon inactive"/>
            <span>Нас</span>
          </router-link>
        </nav>
      </div>
      <search/>
      <auth/>
    </div>
  </div>
</template>

<script>
import Logo from "../../assets/logo.png";
import LogoDark from "../../assets/animax-logo-dark.png";
import {ref} from "vue";
import useHeaderRoute from "@hooks/useHeaderRoute";
import Search from "../Seeroo/Search.vue";
import Auth from "../Auth.vue";
import LogoMini from "../../assets/logo.png";
import {mapActions, mapGetters} from 'vuex';

export default {
  setup() {
    const {currentRoute, routes} = useHeaderRoute();
    const isTop = ref(true);
    return {
      currentRoute,
      routes,
      isTop,
    };
  },

  components: {
    Search,
    Auth
  },

  data() {
    return {
      visibleUserMenu: false,
      LogoMini,
      Logo,
      LogoDark,
      isFixed: false
    };
  },
  computed: {
    ...mapGetters(['showMenu', 'user'])
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    ...mapActions(['toggleSidebar']),
    handleScroll() {
      this.isTop = window.scrollY === 0;
      if (window.scrollY > 60) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },
};
</script>

<style>
.header__overlay {
  background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(10%, rgba(12, 18, 26, 0.7)),
      color-stop(10%, rgba(0, 0, 0, 0))
  );
  background-image: -webkit-linear-gradient(
      top,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
  background-image: -o-linear-gradient(
      top,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
  background-image: linear-gradient(
      to bottom,
      rgba(12, 18, 26, 0.7) 10%,
      rgba(0, 0, 0, 0)
  );
}
</style>
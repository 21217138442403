<template>
    <div class="grid grid-rows-1 grid-flow-col gap-4 flex items-center justify-start rounded bg-[#1f2839] seeroo-card">
      <div class="row-span-2">
        <Image
            :src="data.image"
            class="h-16 w-16 object-cover rounded"/>
      </div>
      <div class="col-span-1 text-sm">
        {{ data.name ? data.name : data.title }}
      </div>
    </div>
</template>

<script>
import Image from "./Image.vue";

export default {
  name: "AnimeDirectorCard",
  props: ["data"],
  components: {
    Image,
  },
};
</script>

<style scoped>

.desc-txt{
  font-size: 14px !important;
}

.video-card img {
  /*@apply object-cover mask mask-circle absolute top-0 left-0 w-full h-full;*/
}
</style>
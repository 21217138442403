<template>
  <Sidebar v-model:visible="visiblePin" header="Нууц үг солих" class="profile-side" position="right" :modal="false" :draggable="false">
      <form class="card-body w-full mt-4" :rules="rules" ref="ruleForm" :model="ruleForm">
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block md:text-right mb-1 md:mb-0 pr-4">
                <span class="label-text">Хуучин пин:</span>
              </label>
            </div>
            <div class="md:w-2/3">
              <Password class="seeroo-pass" v-model="ruleForm.oldPass" invalid placeholder="Хуучин пин"/>
<!--              <input type="password" placeholder="Хуучин нууц үг" v-model="ruleForm.oldPass"-->
<!--                     class="input input-bordered hover:border-accent w-full md:w-3/4">-->
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block md:text-right mb-1 md:mb-0 pr-4">
                <span class="label-text">Шинэ пин:</span>
              </label>
            </div>
            <div class="md:w-2/3">
              <Password class="seeroo-pass" v-model="ruleForm.pass" invalid placeholder="Шинэ пин"/>
<!--              <input type="password" placeholder="Шинэ нууц үг" v-model="ruleForm.pass"-->
<!--                     class="input input-bordered hover:border-accent w-full md:w-3/4">-->
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/3">
              <label class="block md:text-right mb-1 md:mb-0 pr-4">
                <span class="label-text">Шинэ пин:</span>
              </label>
            </div>
            <div class="md:w-2/3">
              <Password class="seeroo-pass" v-model="ruleForm.checkPass" @change="checkPassword" invalid placeholder="Шинэ пин давтах"/>
<!--              <input type="password" placeholder="Шинэ нууц үг давтах" v-model="ruleForm.checkPass"-->
<!--                     class="input input-bordered hover:border-accent w-full md:w-3/4">-->
            </div>
          </div>
          <div class="md:flex md:items-center">
            <div class="md:w-1/3"></div>
            <div class="w-full md:w-2/3 mt-5">
              <Button label="Хадгалах" class="p-button-sm text-white" severity="success" @click="submitForm('ruleForm')"/>
              <Button label="Болих" class="p-button-sm ml-5" @click="resetForm"/>
              <!--            <button class="btn btn-accent btn-sm" @click="submitForm('ruleForm')" role="button">Хадгалах</button>-->
              <!--            <button class="btn btn-accent btn-sm btn-outline ml-3" @click="resetForm('ruleForm')" role="button">Болих</button>-->
            </div>
          </div>
    </form>
  </Sidebar>

</template>

<script>

import Sidebar from 'primevue/sidebar';
import Password from 'primevue/password';

export default {
  name: "PinSidebar",
  components: {
    Sidebar,
    Password
  },
  data() {
    var validateOldPass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Хуучин нууц үгээ оруулна уу!'));
      } else {
        // if (this.ruleForm.checkPass !== '') {
        //     this.$refs.ruleForm.validateField('checkPass');
        // }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Шинэ нууц үгээ оруулна уу!'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Шинэ нууц үгээ дахин оруулна уу!'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('Шинэ нууц үг зөрүүтэй байна!'));
      } else {
        callback();
      }
    };
    return {
      visiblePin: false,
      messages: [],
      ruleForm: {
        oldPass: '',
        pass: '',
        checkPass: ''
      },
      rules: {
        oldPass: [
          { validator: validateOldPass, trigger: 'blur' }
        ],
        ass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    checkPassword() {
      console.log('running');
      if (this.ruleForm.pass && this.ruleForm.checkPass) {
        if (this.ruleForm.pass !==  this.ruleForm.checkPass) {
          console.log('error submit!!');
        }
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      // this.$refs['ruleForm'].resetFields();
      this.ruleForm = {
        oldPass: '',
        pass: '',
        checkPass: ''
      }
    },
    closePage() {
      this.visiblePin = false;
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
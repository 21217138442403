import utils from './utils';
import Thumbnails from './thumbnails';
import Icons from './icons';

class Controller {
    constructor(player) {
        this.player = player;

        this.autoHideTimer = 0;
        if (!utils.isMobile) {
            this.player.container.addEventListener('mousemove', () => {
                this.setAutoHide();
            });

            this.player.container.addEventListener('click', () => {
                this.setAutoHide();
            });

            this.player.on('play', () => {
                this.setAutoHide();
            });

            this.player.on('pause', () => {
                this.setAutoHide();
            });
        }

        this.initPlayButton();
        this.initPlayedBar();
        this.initFullButton();
        this.initQualityButton();
        this.initScreenshotButton();
        this.initSubtitleButton();
        this.initHighlights();
        this.initAirplayButton();
        this.initSeekForward();
        this.initSeekBackward();
        this.initGotoNext();
        this.initGotoPrev();
        this.initGoBack();
        this.initThumbnails();
        this.initAutoSkip();
        this.initAutoPlayNext();

        // if (!utils.isMobile) {
        this.initVolumeButton();
        // }
    }

    initPlayButton() {
        this.player.template.playButton.addEventListener('click', () => {
            this.player.toggle();
        });

        this.player.template.mobilePlayButton.addEventListener('click', () => {
            this.player.toggle();
        });

        if (!utils.isMobile) {
            this.player.template.videoWrap.addEventListener('click', () => {
                this.player.toggle();
            });
            this.player.template.controllerMask.addEventListener('click', () => {
                this.player.toggle();
            });
        } else {
            this.player.template.videoWrap.addEventListener('click', () => {
                this.toggle();
            });
            this.player.template.controllerMask.addEventListener('click', () => {
                this.toggle();
            });
        }
    }

    initHighlights() {
        this.player.on('durationchange', () => {
            if (this.player.video.duration !== 1 && this.player.video.duration !== Infinity) {
                if (this.player.options.highlight) {
                    const highlights = document.querySelectorAll('.dplayer-highlight');
                    [].slice.call(highlights, 0).forEach((item) => {
                        this.player.template.playedBarWrap.removeChild(item);
                    });
                    for (let i = 0; i < this.player.options.highlight.length; i++) {
                        if (!this.player.options.highlight[i].text || !this.player.options.highlight[i].time) {
                            continue;
                        }
                        const p = document.createElement('div');
                        p.classList.add('dplayer-highlight');
                        p.style.left = (this.player.options.highlight[i].time / this.player.video.duration) * 100 + '%';
                        p.innerHTML = '<span class="dplayer-highlight-text">' + this.player.options.highlight[i].text + '</span>';
                        this.player.template.playedBarWrap.insertBefore(p, this.player.template.playedBarTime);
                    }
                }
            }
        });
    }

    initThumbnails() {
        if (this.player.options.video.thumbnails) {
            this.thumbnails = new Thumbnails({
                container: this.player.template.barPreview,
                barWidth: this.player.template.barWrap.offsetWidth,
                url: this.player.options.video.thumbnails,
                events: this.player.events
            });

            this.player.on('loadedmetadata', () => {
                this.thumbnails.resize(160, (this.player.video.videoHeight / this.player.video.videoWidth) * 160, this.player.template.barWrap.offsetWidth);
            });
        }
    }

    initPlayedBar() {
        const thumbMove = (e) => {
            let percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(this.player.template.playedBarWrap)) / this.player.template.playedBarWrap.clientWidth;
            percentage = Math.max(percentage, 0);
            percentage = Math.min(percentage, 1);
            this.player.bar.set('played', percentage, 'width');
            this.player.template.ptime.innerHTML = utils.secondToTime(percentage * this.player.video.duration);
        };

        const thumbUp = (e) => {
            document.removeEventListener(utils.nameMap.dragEnd, thumbUp);
            document.removeEventListener(utils.nameMap.dragMove, thumbMove);
            let percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(this.player.template.playedBarWrap)) / this.player.template.playedBarWrap.clientWidth;
            percentage = Math.max(percentage, 0);
            percentage = Math.min(percentage, 1);
            this.player.bar.set('played', percentage, 'width');
            this.player.seek(this.player.bar.get('played') * this.player.video.duration, false);
            this.player.isDragging = false;

            //Hiding thumbnails on mobile
            if (utils.isMobile) {
                setTimeout(() => {
                    this.thumbnails && this.thumbnails.hide();
                }, 500)
            }
        };

        this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragStart, () => {
            this.player.isDragging = true;
            document.addEventListener(utils.nameMap.dragMove, thumbMove);
            document.addEventListener(utils.nameMap.dragEnd, thumbUp);
        });

        this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragMove, (e) => {
            if (this.player.video.duration) {
                const px = this.player.template.playedBarWrap.getBoundingClientRect().left;
                const tx = (e.clientX || e.changedTouches[0].clientX) - px;
                if (tx < 0 || tx > this.player.template.playedBarWrap.offsetWidth) {
                    return;
                }
                const time = this.player.video.duration * (tx / this.player.template.playedBarWrap.offsetWidth);
                if (utils.isMobile) {
                    this.thumbnails && this.thumbnails.show();
                }
                this.thumbnails && this.thumbnails.move(tx, time);
                this.player.template.playedBarTime.style.left = `${tx - (time >= 3600 ? 25 : 20)}px`;
                this.player.template.playedBarTime.innerText = utils.secondToTime(time);
                this.player.template.playedBarTime.classList.remove('hidden');
            }
        });

        this.player.template.playedBarWrap.addEventListener(utils.nameMap.dragEnd, () => {
            if (utils.isMobile) {
                // this.thumbnails && this.thumbnails.hide();
            }
        });

        if (!utils.isMobile) {
            this.player.template.playedBarWrap.addEventListener('mouseenter', () => {
                if (this.player.video.duration) {
                    this.thumbnails && this.thumbnails.show();
                    this.player.template.playedBarTime.classList.remove('hidden');
                }
            });

            this.player.template.playedBarWrap.addEventListener('mouseleave', () => {
                if (this.player.video.duration) {
                    this.thumbnails && this.thumbnails.hide();
                    this.player.template.playedBarTime.classList.add('hidden');
                }
            });
        } else {
            //Showing thumbnail on mobile
            this.player.template.playedBarWrap.addEventListener('pointerenter', () => {
                // alert("point enter")
                if (this.player.video.duration) {
                    this.thumbnails && this.thumbnails.show();
                    this.player.template.playedBarTime.classList.remove('hidden');
                }
            });
        }
    }

    initFullButton() {
        this.player.template.browserFullButton.addEventListener('click', () => {
            this.player.fullScreen.toggle('browser');
        });

        this.player.template.webFullButton.addEventListener('click', () => {
            this.player.fullScreen.toggle('web');
        });

        document.addEventListener("fullscreenchange", function () {
            if (document.fullscreen) {
                document.body.classList.add('max-player-full');
            } else {
                document.body.classList.remove('max-player-full');
            }
        });
    }

    initVolumeButton() {
        let vWidth = 35;
        const volumeMove = (event) => {
            const e = event || window.event;
            const percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(this.player.template.volumeBarWrap) - 5.5) / vWidth;
            this.player.volume(percentage);
        };
        const volumeUp = () => {
            document.removeEventListener(utils.nameMap.dragEnd, volumeUp);
            document.removeEventListener(utils.nameMap.dragMove, volumeMove);
            this.player.template.volumeButton.classList.remove('dplayer-volume-active');
        };

        this.player.template.volumeBarWrapWrap.addEventListener('click', (event) => {
            const e = event || window.event;
            const percentage = ((e.clientX || e.changedTouches[0].clientX) - utils.getBoundingClientRectViewLeft(this.player.template.volumeBarWrap) - 5.5) / vWidth;
            this.player.volume(percentage);
        });

        this.player.template.volumeBarWrapWrap.addEventListener(utils.nameMap.dragStart, () => {
            document.addEventListener(utils.nameMap.dragMove, volumeMove);
            document.addEventListener(utils.nameMap.dragEnd, volumeUp);
            this.player.template.volumeButton.classList.add('dplayer-volume-active');
        });

        this.player.template.volumeButtonIcon.addEventListener('click', () => {
            if (utils.isMobile) {
                if (this.player.video.muted) {
                    this.player.video.muted = false;
                    this.player.template.volumeIcon.innerHTML = Icons.volumeUp;
                } else {
                    this.player.video.muted = true;
                    this.player.template.volumeIcon.innerHTML = Icons.volumeOff;
                }
            } else {
                if (this.player.video.muted) {
                    this.player.video.muted = false;
                    this.player.sound.muteAudio(false);
                    this.player.switchVolumeIcon();
                    this.player.bar.set('volume', this.player.volume(), 'width');
                } else {
                    this.player.video.muted = true;
                    this.player.sound.muteAudio(true);
                    this.player.template.volumeIcon.innerHTML = Icons.volumeOff;
                    this.player.bar.set('volume', 0, 'width');
                }
            }
        });
    }

    initQualityButton() {
        if (this.player.options.video.quality) {
            this.player.template.qualityList.addEventListener('click', (e) => {
                if (e.target.classList.contains('dplayer-quality-item')) {
                    this.player.switchQuality(e.target.dataset.index);

                    //Checkin active quality
                    this.checkActiveQuality(e.target.dataset.index);
                }
            });
        }

        try {
            if (localStorage.getItem('quality')) {
                this.checkActiveQuality(localStorage.getItem('quality'));
            }
        } catch (e) {
            this.checkActiveQuality(0);
        }
    }

    checkActiveQuality(index) {
        let els = document.querySelectorAll('.max-quality-check');
        els.forEach(function (el) {
            el.style.display = "none";
        });
        let checkEl = document.querySelector(`#quality-check-${index}`);
        if (checkEl) {
            checkEl.style.display = "inline-flex";
        }
    }

    initScreenshotButton() {
        if (this.player.options.screenshot) {
            this.player.template.camareButton.addEventListener('click', () => {
                const canvas = document.createElement('canvas');
                canvas.width = this.player.video.videoWidth;
                canvas.height = this.player.video.videoHeight;
                canvas.getContext('2d').drawImage(this.player.video, 0, 0, canvas.width, canvas.height);

                let dataURL;
                canvas.toBlob((blob) => {
                    dataURL = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = dataURL;
                    link.download = 'MaxPlayer.png';
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(dataURL);
                });

                this.player.events.trigger('screenshot', dataURL);
            });
        }
    }

    initAirplayButton() {
        // if (this.player.options.airplay) {
        //     if (window.WebKitPlaybackTargetAvailabilityEvent) {
        //         this.player.video.addEventListener(
        //             'webkitplaybacktargetavailabilitychanged',
        //             function (event) {
        //                 switch (event.availability) {
        //                     case 'available':
        //                         this.template.airplayButton.disable = false;
        //                         break;
        //
        //                     default:
        //                         this.template.airplayButton.disable = true;
        //                 }
        //
        //                 this.template.airplayButton.addEventListener(
        //                     'click',
        //                     function () {
        //                         this.video.webkitShowPlaybackTargetPicker();
        //                     }.bind(this)
        //                 );
        //             }.bind(this.player)
        //         );
        //     } else {
        //         this.player.template.airplayButton.style.display = 'none';
        //     }
        // }
    }

    initSubtitleButton() {
        // if (this.player.options.subtitle) {
        //     this.player.events.on('subtitle_show', () => {
        //         // this.player.template.subtitleButton.dataset.balloon = this.player.tran('Hide subtitle');
        //         // this.player.template.subtitleButtonInner.classList.add('max-active-sub');
        //         this.player.user.set('subtitle', 1);
        //     });
        //
        //     this.player.events.on('subtitle_hide', () => {
        //         // this.player.template.subtitleButton.dataset.balloon = this.player.tran('Show subtitle');
        //         // this.player.template.subtitleButtonInner.classList.remove('max-active-sub');
        //         this.player.user.set('subtitle', 0);
        //     });
        //
        //     this.player.template.subtitleButton.addEventListener('click', () => {
        //         this.player.subtitle.toggle();
        //     });
        //
        //     if (this.player.user.get('subtitle') == 1) {
        //         this.player.template.subtitleButtonInner.style.opacity = '';
        //         this.player.template.subtitleButtonInner.classList.add('max-active-sub');
        //     }
        // }
    }

    setAutoHide() {
        this.show();
        clearTimeout(this.autoHideTimer);
        this.autoHideTimer = setTimeout(() => {
            if (this.player.video.played.length && !this.player.paused && !this.disableAutoHide) {
                this.hide();
            }
        }, 3000);

        // if (this.player.container.classList.contains('dplayer-hide-controller')) {
        //     this.player.template.subtitle.style.bottom = "40px";
        // }
    }

    show() {
        this.player.container.classList.remove('dplayer-hide-controller');
        this.player.setSubStyle(this.player.container, this.player.template);
    }

    hide() {
        this.player.container.classList.add('dplayer-hide-controller');
        this.player.template.subtitle.classList.remove('equal-container');

        this.player.setting.hide();
        this.player.comment && this.player.comment.hide();
    }

    isShow() {
        return !this.player.container.classList.contains('dplayer-hide-controller');
    }

    toggle() {
        if (this.isShow()) {
            this.hide();
        } else {
            this.show();
        }
    }

    destroy() {
        clearTimeout(this.autoHideTimer);
    }

    initSeekForward() {
        this.player.template.seekForwardBtn.addEventListener('click', () => {
            if (this.player.options.live) {
                return;
            }
            this.player.seek(this.player.video.currentTime + 10, false);
            this.player.notice(`>> ${utils.secondToTime((this.player.video.currentTime + 10).toFixed(0))}`);
        })
    }

    initSeekBackward() {
        this.player.template.seekBackwardBtn.addEventListener('click', () => {
            if (this.player.options.live) {
                return;
            }
            this.player.seek(this.player.video.currentTime - 10, false);
            this.player.notice(`<< ${utils.secondToTime((this.player.video.currentTime - 10).toFixed(0))}`);
        })
    }

    initGoBack() {
        if (this.player.template.backBtn) {
            this.player.template.backBtn.addEventListener('click', () => {
                this.player.fullScreen.cancel('web');
                this.player.options.goBack();
            });
        }
    }

    initGotoNext() {
        if (this.player.template.nextMediaBtn) {
            this.player.template.nextMediaBtn.addEventListener('click', () => {
                if (this.player.options.next) {
                    this.destroy();
                    this.player.options.next.handler();
                }
            });
        }
    }

    initGotoPrev() {
        if (this.player.template.prevMediaBtn) {
            this.player.template.prevMediaBtn.addEventListener('click', () => {
                if (this.player.options.prev) {
                    this.destroy();
                    this.player.options.prev.handler();
                }
            });
        }
    }

    initAutoSkip() {
        if (this.player.template.autoSkip) {
            this.player.template.autoSkip.addEventListener('click', () => {
                this.player.template.autoSkip.classList.toggle("toggle-on");

                if (this.player.template.autoSkip.classList.contains("toggle-on")) {
                    localStorage.setItem('is-auto-skip', true);
                } else {
                    localStorage.setItem('is-auto-skip', false);
                }
            });

            let isset = localStorage.getItem('is-auto-skip');
            if (isset && (isset == 'true' || isset == true)) {
                this.player.template.autoSkip.click();
            }
        }
    }

    initAutoPlayNext() {
        if (this.player.template.autoPlaySwitch) {
            this.player.template.autoPlaySwitch.addEventListener('click', () => {
                this.player.template.autoPlaySwitch.classList.toggle("toggle-on");
                if (this.player.template.autoPlaySwitch.classList.contains("toggle-on")) {
                    localStorage.setItem('is-auto-play', true);
                } else {
                    localStorage.setItem('is-auto-play', false);
                }
            });

            let isset = localStorage.getItem('is-auto-play');
            if (isset && (isset == 'true' || isset == true)) {
                this.player.template.autoPlaySwitch.click();
            }else{
                localStorage.setItem('is-auto-play', false);
            }
        }
    }
}

export default Controller;

<template>
  <div class="stream-page">
    <div class="stream-page-flex">
      <div class="stream-watch">
        <div v-if="isLoading || movie == null" class="loader-wrap">
          <ProgressSpinner/>
        </div>

        <div v-else class="player-wrap">
          <div v-if="isLoading || movie == null" class="loader-wrap">
            <ProgressSpinner/>
          </div>

          <div v-else-if="!isLoading && movie != null">
            <div v-if="canWatch" class="video-wrapper theatre-video-watch">
              <max-player v-if="!noVideo" ref="player" :options="options"/>
              <div v-else class="no-video">
                <i class="isax isax-emoji-sad"></i>
                <span>Уучлаарай бичлэг олдсонгүй</span>
                <a href="javascript:void(0)" @click="goBack">
                  <i class="isax isax-arrow-left"></i>
                  <span>Буцах</span>
                </a>
              </div>
            </div>

            <div v-else>
              <div class="watch-warning py-16 md:py-36 sm:py-16">
                <span class="text-center" v-if="movie.is_theatre == 1">{{ warnMsg }}</span>
                <div class="nav-to-auth" v-if="user == null">
                  <a href="/login/qr">
                    <i class="uil-user"></i>
                    <span>Нэвтрэх</span>
                  </a>
                  <a href="/register">
                    <i class="uil-pen"></i>
                    <span>Бүртгүүлэх</span>
                  </a>
                </div>

                <div v-else class="nav-to-auth mini payment-qpay">

                  <div v-if="movie.is_theatre == 0">
                    <div class="watch-warning py-16 md:py-36 sm:py-16">
                      <i class="isax isax-emoji-sad"></i>
                      <span class="text-center">{{ warnMsg }}</span>

                      <div class="nav-to-auth">
                        <router-link to="/pack" style="height: 80px !important;">
                          <i class="uil-credit-card"></i>
                          <span>Эрх сунгах</span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div v-else>

                    <div v-if="step == 1">
                      <div class="price-info">
                        {{ tr("5", "72") }} цаг түрээслэх үнэ:
                        <strong>{{ this.movie.price }}₮</strong>
                      </div>

                      <div class="price-actions">
                        <a href="javascript:void(0)" @click="createQpayInvoice()">
                          <span v-if="isProceeding">Түр хүлээнэ үү...</span>
                          <span v-else>{{ tr("Qpay-р төлөх", "Түрээслэх") }}</span>
                        </a>

                        <a href="javascript:void(0)" @click="goBack()">
                          <span>Болих</span>
                        </a>
                      </div>
                    </div>

                    <div v-if="step === 2">
                      <div class="payment-options qpay-render qr-v2">
                        <img class="qpay-img" v-if="qpayInvoice != null"
                             :src="`data:image/png;base64,${qpayInvoice.qr_image}`" alt=""/>
                        <!--                      <div class="counter">{{ count < 10 ? `0${count}` : count }} сек</div>-->

                        <div class="deeplink" v-if="qpayInvoice != null">
                          <h3>Та утсан дээрээ суулгасан банкны аппликейшнээ сонгож төлбөрөө хийнэ
                            үү</h3>
                          <ul>
                            <li>
                              <a :data-app="`khanbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.slide.khaanbank"
                                 data-store-ios="610317572"
                                 :href="`khanbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/1.png" alt="khanbank"/>

                              </a>
                            </li>
                            <li>
                              <a :data-app="`tdbbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.tdb.pay" data-store-ios="1458831706"
                                 :href="`tdbbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/2.png" alt="tdbbank"/>

                              </a>
                            </li>
                            <li>
                              <a :data-app="`statebank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.statebank.mobilebank"
                                 data-store-ios="703343972"
                                 :href="`statebank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/3.png" alt="statebank"/>

                              </a>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <a :data-app="`xacbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.xacbank.smartbank"
                                 data-store-ios="781439021"
                                 :href="`xacbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/4.png" alt="xacbank"/>
                              </a>
                            </li>
                            <li>
                              <a :data-app="`capitronbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.ecapitron" data-store-ios="1312706504"
                                 :href="`capitronbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/5.png"
                                     alt="capitronbank"/>

                              </a>
                            </li>
                            <li>
                              <a :data-app="`ckbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.ckbank.smartbank2"
                                 data-store-ios="1180620714"
                                 :href="`ckbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/6.png" alt="ckbank"/>

                              </a>
                            </li>
                          </ul>
                          <ul>
                            <li>
                              <a :data-app="`bogdbank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="com.bogdbank.ebank.v2"
                                 data-store-ios="1475442374"
                                 :href="`bogdbank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/7.png" alt="bogdbank"/>

                              </a>
                            </li>
                            <li>
                              <a :data-app="`nibank://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.nibank.mobilebank"
                                 data-store-ios="882075781"
                                 :href="`nibank://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/8.jpeg" alt="nibank"/>

                              </a>
                            </li>
                            <li>
                              <a :data-app="`most://q?qPay_QRcode=${qpayInvoice.qr_text}`"
                                 data-store-android="mn.grapecity.mostmoney"
                                 data-store-ios="487144325"
                                 :href="`most://q?qPay_QRcode=${qpayInvoice.qr_text}`">
                                <img src="/assets/max/images/banks/9.png" alt="most"/>
                              </a>
                            </li>
                          </ul>
                        </div>

                        <el-alert
                            description="QR код нь 90 cекунд идэвхитэй байх ба энэхүү хугацаанд төлбөрөө төлж амжаагүй бол дахин эхнээс нь багцаа сонгох үйлдлээ хийнэ үү."
                            type="warning"
                            :closable="false">
                        </el-alert>

                        <p class="warning-txt">
                          Төлбөр хийгдсэний дараа дараагийн алхам руу автоматаар шилжинэ. Та
                          төлбөр төлөгдөх хүртэл түр
                          хүлээнэ үү!
                        </p>

                        <br/>

                        <div class="price-actions">
                          <a href="javascript:void(0)" @click="checkPayment()">
                            <span v-if="isProceeding">Түр хүлээнэ үү...</span>
                            <span v-else>Шалгах</span>
                          </a>

                          <a href="javascript:void(0)" @click="cancelQpay()">
                            <span>Цуцлах</span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div v-if="step === 3" class="result-step">
                      <div class="qpay-result">
                        <div v-if="isSuccess" class="success">
                          <div class="icon-wrap">
                            <i class="ti-face-smile"></i>
                          </div>
                          <p>{{ resultMsg }}</p>
                        </div>

                        <div v-else class="error">
                          <div class="icon-wrap">
                            <i class="ti-face-sad"></i>
                          </div>
                          <p>{{ resultMsg }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {imageUrl} from "../../utils";
import Hls from "hls.js";
import api from "../../store/_boot/baseApi";
import {tr} from "../../utils"
import _ from "lodash";

export default {
  components: {},

  el: "#nav",
  data() {
    return {
      isLoading: false,
      noVideo: false,
      isBlock: false,
      canWatch: false,
      warnMsg: "",
      qpayInvoice: null,
      step: 1,
      isProceeding: false,
      isLoadingQpay: false,
      isSuccess: false,
      resultMsg: "",
      bill: null,
      options: {
        mediaId: null,
        userId: null,
        theme: window.init.appColor,
        autoplay: true,
        screenshot: false,
        loop: false,
        mutex: true,
        airplay: false,
        theatreMode: true,
        goBack: this.goBack,
        contextmenu: [],
      }
    };
  },

  computed: {
    ...mapGetters(['movie', 'backLink', 'user']),
  },

  mounted() {
    this.getMovie({vm: this, id: this.$route.params.id});
  },

  beforeMount() {
    const body = document.querySelector("body");
    body.classList.add('media-open');
  },

  unmounted() {
    const body = document.querySelector("body");

    setTimeout(() => {
      body.classList.remove('media-open');
    }, 300)
  },

  methods: {
    tr: tr,
    imageUrl,
    ...mapActions(['getMovie']),

    async initWatch() {
      this.isLoading = true;
      if (this.movie.vid1 == null && this.movie.vid2 == null && this.movie.vid3 == null) {
        this.noVideo = true;
      } else {
        await this.setPlayerInfo();
      }
      this.isLoading = false;
    },

    async setPlayerInfo() {
      this.isLoading = true;
      await this.setSource();
      await this.setAudio();
      this.setTitle();
      this.setSubTitle();
      console.log('info called');

      this.isLoading = false;
    },

    setTitle() {
      this.options.title = {};
      this.options.title.main = this.movie.atitle;
      if (this.movie.title != null) {
        this.options.title.sub = this.movie.title;
      }
    },

    setSubTitle() {
      if (this.movie.subs.length > 0) {
        let subs = _.cloneDeep(this.movie.subs);
        console.log(subs);

        subs = subs.map(item => {
          if (item.isRaw == 0) {
            item.key = [6, 13, 14, 18, 19, 26, 30, 38, 42, 45, 48, 53, 62, 63, 67, 76];
            item.iv = [9, 15, 19, 24, 32, 43, 44, 46, 48, 49, 59, 64, 71, 83, 87, 93];
          }

          if (localStorage.getItem('sub') && localStorage.getItem('sub') == item.code) {
            this.options.subtitle = item;
          }
          return item;
        })
        this.options.subs = subs;

        if (this.options.subtitle == null && localStorage.getItem('sub') && localStorage.getItem('sub') == 'mn') {
          let issetEnSub = subs.find(s => s.code == 'en');
          if (issetEnSub) {
            this.options.subtitle = issetEnSub;
          }
        }
      }

      // if (this.movie.subs.length > 0) {
      //   this.options.subs = this.movie.subs;
      // }
      // if (this.movie.sub_url !== null) {
      //   let url = this.movie.sub_url;
      //   if (!this.movie.sub_url.includes('http')) {
      //     url = `data:text/vtt;base64,${this.movie.sub_url}`
      //   }
      //
      //   this.options.subtitle = {
      //     url: url,
      //     type: 'webvtt',
      //     bottom: 40,
      //     key: [6, 13, 14, 18, 19, 26, 30, 38, 42, 45, 48, 53, 62, 63, 67, 76],
      //     iv: [9, 15, 19, 24, 32, 43, 44, 46, 48, 49, 59, 64, 71, 83, 87, 93]
      //   };
      // }
    },

    iOS() {
      return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
          ].includes(navigator.platform)
          // iPad on iOS 13 detection
          || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    },

    replaceExt(vid) {
      let ext = vid.split(/[#?]/)[0].split('.').pop().trim();
      let isIOS = this.iOS();
      if (isIOS && (ext == 'wmx' || ext == 'max') && this.$app == 'wemax') {
        return vid.substr(0, vid.lastIndexOf(".")) + ".m3u8";
      }
      return vid;
      // return "https://www.dropbox.com/s/df2d2gf1dvnr5uj/Sample_1280x720_mp4.mp4";
    },

    getExt(vid) {
      let ext = vid.split(/[#?]/)[0].split('.').pop().trim();
      if (Hls.isSupported() && (ext == "m3u8" || ext == "wmx" || ext == "max")) {
        ext = 'hls';
      }
      return ext;
    },

    async setSource() {
      let qualities = []
      let currentIndex = 0;
      let has480 = false;
      let has720 = false;
      let has1080 = false;

      if (this.movie.vid1 !== null) {
        has480 = true;
        let vid = {
          url: this.replaceExt(this.movie.vid1),
          type: this.getExt(this.movie.vid1),
          name: '480 SD',
        };
        qualities.push(vid);
      }

      if (this.movie.vid2 !== null) {
        has720 = true
        let vid = {
          url: this.replaceExt(this.movie.vid2),
          type: this.getExt(this.movie.vid2),
          name: '720 HD',
        };
        qualities.push(vid);
      }

      if (this.movie.vid3 !== null) {
        has1080 = true;
        let vid = {
          url: this.replaceExt(this.movie.vid3),
          type: this.getExt(this.movie.vid3),
          name: '1080 QHD',
        };
        qualities.push(vid);
      }

      if (localStorage.getItem("quality") !== null) {
        let savedIndex = localStorage.getItem("quality");

        if (savedIndex.toString() === '0' && has480) {
          currentIndex = 0;
        }

        if (savedIndex.toString() === '1' && has720 && qualities.length >= 2) {
          currentIndex = 1;
        }

        if (savedIndex.toString() === '1' && has720 && qualities.length === 1) {
          currentIndex = 0;
        }

        if (savedIndex.toString() === '2' && has1080 && qualities.length === 3) {
          currentIndex = 2;
        }

        if (savedIndex.toString() === '2' && has1080 && qualities.length === 2) {
          currentIndex = 1;
        }

        if (savedIndex.toString() === '2' && has1080 && qualities.length === 1) {
          currentIndex = 0;
        }
      }

      this.options.video = {
        quality: qualities,
        defaultQuality: currentIndex
      }
    },

    loadDubDefaultOption(setStorage) {
      this.movie.dubs.some((item) => {
        if (item.key == 'mn') {
          this.options.audio = item.url;
          if (setStorage) {
            localStorage.setItem('sound', 'mn');
          }
          return 'mn';
        }

        if (item.key == 'en') {
          this.options.audio = item.url;
          if (setStorage) {
            localStorage.setItem('sound', 'en');
          }
          return 'en';
        }
      })
    },

    async setAudio() {
      if (this.movie.audio != null) {
        this.options.audio = this.movie.audio;
      }

      if (this.movie.dubs) {
        this.options.dubs = this.movie.dubs;
        if (localStorage.getItem('sound')) {
          let issetInDubs = this.movie.dubs.find(item => item.key == localStorage.getItem('sound'));
          if (issetInDubs) {
            this.movie.dubs.forEach((item) => {
              if (item.key == localStorage.getItem('sound')) {
                this.options.audio = item.url
              }
            })
          } else {
            this.loadDubDefaultOption(false)
          }
        } else {
          this.loadDubDefaultOption(true);
        }
      }
    },

    goBack() {
      this.$router.push('/theatre/detail/' + this.$route.params.id);
    },

    setStatus(action, value) {
      if (this.user == null) {
        this.$toast.error("Нэвтэрч орсны дараа уг үйлдлийг хийх боломжтой");
        return;
      }

      this.$store.dispatch('setEpStatus', {vm: this, action, value})
    },

    checkPermission(id) {
      this.isLoading = true;
      this.canWatch = false;
      if (this.user != null) {
        // hervee theatre gorimd bval
        if (this.movie != null && this.movie.is_theatre == 1) {
          if ((this.movie.has_price === false || this.movie.has_price === 0) && this.movie.price == "0") {
            this.initWatch();
            this.canWatch = true;
            this.isLoading = false;
          } else {
            api.get(`/api/m/tvod/check/${this.user.id}/${id}`).then(async ({data}) => {
              this.canWatch = data.status;
              await this.initWatch();
            }).catch(e => {
              console.log(e);
              this.isLoading = false;
            })
          }
        } else {
          if(this.movie != null && this.movie.is_free == 1) {
            this.initWatch();
            this.canWatch = true;
          }else {
            if (this.user.days <= 0) {
              this.isLoading = false;
              this.warnMsg = "Та эрхээ сунгаж үзвэрээ үзнэ үү!";
            } else {
              this.initWatch();
              this.canWatch = true;
            }
          }
        }
      } else {
        this.isLoading = false;
        this.warnMsg = "Та нэвтэрч орсны дараа үзэх боломжтой!";
      }
    },

    resetQpay() {
      clearInterval(this.countTimer);
      this.isProceeding = false;
    },

    createQpayInvoice() {
      if (this.isProceeding) {
        return false;
      }

      this.isProceeding = true;
      this.btnTxt = "Түр хүлээнэ үү";
      api.get(`/api/qpay/invoice/${this.user.id}/${this.movie.id}/movie`).then(({data}) => {
        if (data.status) {
          this.qpayInvoice = data.data;
          setTimeout(() => {
            this.step++;
            this.isProceeding = false;
            this.bill = data.bill;
            // this.countTimer = setInterval(() => {
            //   this.count--;
            //   if (this.count == 0) {
            //     this.resetQpay();
            //     this.$notify.error({
            //       title: 'Хугацаа дууслаа',
            //       message: 'Та төлбөр төлөх хүсэлтээ дахин илгээнэ үү'
            //     });
            //   }
            // }, 1000);
            // this.checkPayment(data.bill);
          }, 1000);
        } else {
          setTimeout(() => {
            this.isProceeding = false;
            this.$Message.error(data.msg);
          }, 1000);
        }
      }).catch(e => {
        console.log(e);
        setTimeout(() => {
          this.isProceeding = false;
          this.$Message.error("Алдаа гарлаа");
          this.btnTxt = "Үргэлжлүүлэх";
        }, 1000);
      })
    },

    checkPayment() {
      api.get(`/api/qpay/check/${this.bill}`).then(({data}) => {
        if (data.status == 1) {
          this.gotoFinal();
          this.isSuccess = true;

          this.resultMsg = data.msg;
          setTimeout(() => {
            window.document.location.reload();
          }, 3000);
        }

        if (data.status == 0) {
          this.gotoFinal();
          this.isSuccess = false;
          this.resultMsg = data.msg;
        }

        // if (data.status == 2 && this.step == 2) {
        //   setTimeout(() => {
        //     this.checkPayment(bill);
        //   }, 2000)
        // }
      }).catch(e => {
        console.log(e);
        this.cancelQpay();
        this.$notify.error({
          title: 'Алдаа гарлаа',
          message: 'Шалгахад алдаа гарлаа'
        });
      })
    },

    gotoFinal() {
      this.step = 3;
      clearInterval(this.countTimer);
      this.paymentType = null;
      this.selectedCard = null;
      this.isDisabled = true;
      this.isProceeding = false;
      this.count = 90;
    },

    cancelQpay() {
      this.step = 1;
      this.resetQpay();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.speeddial-linear-demo) {
  .p-speeddial-direction-down {
    left: calc(50% - 2rem);
    top: 0;
  }
}
</style>

import api from "../_boot/baseApi";

export const getHomeSlide = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get("/api/m/slides/big?multi=1").then(({data}) => {
        commit('setHomeSlide', data);
        payload.vm.isLoading = false;
    });
}

//Playmax - anime
export const getMediaSlide = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get("/api/m/slides/big/2?multi=1").then(({data}) => {
        commit('setMediaSlide', data);
        payload.vm.isLoading = false;
    });
}

//Playmax - series
export const getMovieSlide = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get("/api/m/slides/big/3?multi=1").then(({data}) => {
        commit('setMovieSlide', data);
        payload.vm.isLoading = false;
    });
}

//Playmax - theatre
export const getTheatreSlide = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get("/api/m/slides/big/4?multi=1").then(({data}) => {
        commit('setTheatreSlide', data);
        payload.vm.isLoading = false;
    });
}

//Other pages
export const getPageSlide = ({commit}, payload) => {
    payload.vm.isLoading = true;
    api.get("/api/m/slides/big/5").then(({data}) => {
        commit('setPageSlide', data);
        payload.vm.isLoading = false;
    });
}
const getters = {
    user: (state) => state.user,
    mirror: (state) => state.mirror,
    // user(state) {
    //     return state.user;
    // },
    isLoadingAuth(state) {
        return state.isLoadingAuth;
    },
    isLoadingLatestEps: (state) => state.isLoadingLatestEps,
    showMenu: (state) => state.showMenu,
    backLink: (state) => state.backLink,

    //Slides
    homeSlide: (state) => state.homeSlide,
    mediaSlide: (state) => state.mediaSlide,
    movieSlide: (state) => state.movieSlide,
    videoSlide: (state) => state.videoSlide,
    pageSlide: (state) => state.pageSlide,

    //Media
    latestMovies: (state) => state.latestMovies,
    popularMedias: (state) => state.popularMedias,
    upComingMedias: (state) => state.upComingMedias,
    homeCatMedias: (state) => state.homeCatMedias,
    mediaList: (state) => state.mediaList,
    actors: (state) => state.actors,
    relatedMedias: (state) => state.relatedMedias,
    seasonMedias: (state) => state.seasonMedias,
    cats: (state) => state.cats,
    seasons: (state) => state.seasons,
    suggestedMedias: (state) => state.suggestedMedias,

    //Latest
    latestEpisodes: (state) => state.latestEpisodes,
    historyEpisodes: (state) => state.historyEpisodes,
    latestHistoryEpisodes: (state) => state.latestHistoryEpisodes,

    //Media
    media: (state) => state.media,
    isLoadingEpisode: (state) => state.isLoadingEpisode,
    episode: (state) => state.episode,
    episodes: (state) => state.episodes,
    allEpisodes: (state) => state.allEpisodes,
    episodeGroupList: (state) => state.episodeGroupList,
    canWatch: (state) => state.canWatch,
    isFree: (state) => state.isFree,
    warnMsg: (state) => state.warnMsg,
    comments: (state) => state.comments,
    commentCount: (state) => state.commentCount,

    //Content
    contentData: (state) => state.contentData,

    //Payment
    paymentData: (state) => state.paymentData,
    paymentOptions: (state) => state.paymentOptions,

    //Movie
    movieCats: (state) => state.movieCats,
    movies: (state) => state.movies,
    movie: (state) => state.movie,
}

export default getters;
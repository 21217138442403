import Promise from 'promise-polyfill';

import utils from './utils';
import handleOption from './options';
import i18n from './i18n';
import Template from './template';
import Icons from './icons';
import Danmaku from './danmaku';
import Events from './events';
import FullScreen from './fullscreen';
import User from './user';
import Subtitle from './subtitle';
import Bar from './bar';
import Timer from './timer';
import Bezel from './bezel';
import Controller from './controller';
import Setting from './setting';
import Sound from './sound';
import Comment from './comment';
import HotKey from './hotkey';
// import ContextMenu from './contextmenu';
import InfoPanel from './info-panel';
import tplVideo from '../template/video.art';
import hls from "hls.js"
import Subctrl from "./subctrl";

window.Hls = hls;

let index = 0;
const instances = [];
let updateTime = 0;
let lastSentTime = 0;


class MaxPlayer {
    /**
     * MaxPlayer constructor function
     **/

    constructor(options) {
        this.options = handleOption({preload: options.video.type === 'webtorrent' ? 'none' : 'metadata', ...options});

        if (this.options.video.quality) {
            this.qualityIndex = this.options.video.defaultQuality;
            this.quality = this.options.video.quality[this.options.video.defaultQuality];
        }
        this.tran = new i18n(this.options.lang).tran;
        this.events = new Events();
        this.user = new User(this);
        this.container = this.options.container;
        this.isDragging = false;

        this.container.classList.add('dplayer');
        if (!this.options.danmaku) {
            this.container.classList.add('dplayer-no-danmaku');
        }
        if (this.options.live) {
            this.container.classList.add('dplayer-live');
        } else {
            this.container.classList.remove('dplayer-live');
        }

        if (utils.isMobile) {
            this.container.classList.add('dplayer-mobile');
        }

        this.arrow = this.container.offsetWidth <= 500;
        if (this.arrow) {
            this.container.classList.add('dplayer-arrow');
        }

        this.template = new Template({
            container: this.container,
            options: this.options,
            index: index,
            tran: this.tran,
        });

        this.video = this.template.video;

        this.bar = new Bar(this.template);

        this.bezel = new Bezel(this.template.bezel);

        this.fullScreen = new FullScreen(this);

        this.controller = new Controller(this);

        if (this.options.danmaku) {
            this.danmaku = new Danmaku({
                container: this.template.danmaku,
                opacity: this.user.get('opacity'),
                callback: () => {
                    setTimeout(() => {
                        this.template.danmakuLoading.style.display = 'none';

                        // autoplay
                        if (this.options.autoplay) {
                            this.play();
                        }
                    }, 0);
                },
                error: (msg) => {
                    this.notice(msg);
                },
                apiBackend: this.options.apiBackend,
                borderColor: this.options.theme,
                height: this.arrow ? 24 : 30,
                time: () => this.video.currentTime,
                unlimited: this.user.get('unlimited'),
                api: {
                    id: this.options.danmaku.id,
                    address: this.options.danmaku.api,
                    token: this.options.danmaku.token,
                    maximum: this.options.danmaku.maximum,
                    addition: this.options.danmaku.addition,
                    user: this.options.danmaku.user,
                },
                events: this.events,
                tran: (msg) => this.tran(msg),
            });

            this.comment = new Comment(this);
        }

        this.setting = new Setting(this);
        this.sound = new Sound(this);
        new Subctrl(this);
        this.plugins = {};

        document.addEventListener(
            'click',
            () => {
                this.focus = false;
            },
            true
        );

        this.container.addEventListener('click', () => {
                this.focus = true;
            },
            true
        );

        this.paused = true;
        this.timer = new Timer(this);
        this.hotkey = new HotKey(this);
        // this.contextmenu = new ContextMenu(this);

        this.initVideo(this.video, (this.quality && this.quality.type) || this.options.video.type);
        this.infoPanel = new InfoPanel(this);

        if (!this.danmaku && this.options.autoplay) {
            this.play();
        }

        index++;
        instances.push(this);

        if (this.template.skipOpBtn) {
            this.template.skipOpBtn.addEventListener('click', () => {
                this.seek(this.options.skipOp.end, false);
            });
        }

        if (this.template.skipEdBtn) {
            this.template.skipEdBtn.addEventListener('click', () => {
                this.seek(this.options.skipEd.end, false);
            });
        }

        if (this.options.subtitle) {
            window.addEventListener('resize', () => {
                this.setSubStyle(this.container, this.template);
            });
        }

        try {
            if (localStorage.getItem('speed')) {
                this.speed(localStorage.getItem('speed'));
            }
        } catch (e) {
            this.template.currentSpeed.innerHTML = this.video.playbackRate;
        }
    }

    setSubStyle(container, template) {
        if (template.container.offsetHeight == template.video.offsetHeight) {
            if (template.container.classList.contains('dplayer-hide-controller')) {
                template.subtitle.classList.remove('equal-container');
            } else {
                template.subtitle.classList.add('equal-container');
            }
        } else {
            template.subtitle.classList.remove('equal-container');
        }
    }

    /**
     * Seek video
     */
    seek(time, shouldNotice = true, initSeek = false) {
        time = Math.max(time, 0);
        if (this.video.duration) {
            time = Math.min(time, this.video.duration);
        }

        if (shouldNotice) {
            if (this.video.currentTime < time) {
                this.notice(`>> ${(time - this.video.currentTime).toFixed(0)} ${this.tran('сек')}`);
            } else if (this.video.currentTime > time) {
                this.notice(`<< ${(this.video.currentTime - time).toFixed(0)} ${this.tran('сек')}`);
            }
        }

        this.video.currentTime = time;
        this.sound.seekAudio(time);

        if (this.danmaku) {
            this.danmaku.seek();
        }
        this.bar.set('played', time / this.video.duration, 'width');
        this.template.ptime.innerHTML = utils.secondToTime(time);

        if (!initSeek) {
            this.saveLog(true, false);
        }
    }

    /**
     * Play video
     */
    play(fromNative) {
        this.paused = false;
        if (this.video.paused && !utils.isMobile) {
            this.bezel.switch(Icons.play);
        }

        this.template.playButton.innerHTML = Icons.pause;
        this.template.mobilePlayButton.innerHTML = Icons.pause;

        if (!fromNative) {
            const playedPromise = Promise.resolve(this.video.play());
            playedPromise
                .catch(() => {
                    this.pause();
                    this.sound.audioPause();
                })
                .then(() => {
                    this.sound.audioPlay();
                });
        }
        this.timer.enable('loading');
        this.container.classList.remove('dplayer-paused');
        this.container.classList.add('dplayer-playing');

        if (this.danmaku) {
            this.danmaku.play();
        }

        if (this.options.mutex) {
            for (let i = 0; i < instances.length; i++) {
                if (this !== instances[i]) {
                    instances[i].pause();
                }
            }
        }
    }

    /**
     * Pause video
     */
    pause(fromNative) {
        this.paused = true;
        this.container.classList.remove('dplayer-loading');

        if (!this.video.paused && !utils.isMobile) {
            this.bezel.switch(Icons.pause);
        }

        this.template.playButton.innerHTML = Icons.play;
        this.template.mobilePlayButton.innerHTML = Icons.play;
        if (!fromNative) {
            this.video.pause();
            this.sound.audioPause();
        }
        this.timer.disable('loading');
        this.container.classList.remove('dplayer-playing');
        this.container.classList.add('dplayer-paused');
        if (this.danmaku) {
            this.danmaku.pause();
        }
    }

    switchVolumeIcon() {
        if (this.volume() >= 0.95) {
            this.template.volumeIcon.innerHTML = Icons.volumeUp;
        } else if (this.volume() > 0) {
            this.template.volumeIcon.innerHTML = Icons.volumeDown;
        } else {
            this.template.volumeIcon.innerHTML = Icons.volumeOff;
        }
    }

    /**
     * Set volume
     */
    volume(percentage, nostorage, nonotice) {
        percentage = parseFloat(percentage);
        if (!isNaN(percentage)) {
            percentage = Math.max(percentage, 0);
            percentage = Math.min(percentage, 1);
            this.bar.set('volume', percentage, 'width');
            const formatPercentage = `${(percentage * 100).toFixed(0)}%`;
            this.template.volumeBarWrapWrap.dataset.balloon = formatPercentage;
            if (!nostorage) {
                this.user.set('volume', percentage);
            }

            if (!nonotice) {
                this.notice(`Дууны хэмжээ ${(percentage * 100).toFixed(0)}%`);
            }

            this.video.volume = percentage;
            this.sound.setAudioVolume(percentage);
            if (this.video.muted) {
                this.video.muted = false;
            }
            this.switchVolumeIcon();
        }

        return this.video.volume;
    }

    /**
     * Toggle between play and pause
     */
    toggle() {
        if (this.video.paused) {
            this.play();
        } else {
            this.pause();
        }
    }

    /**
     * attach event
     */
    on(name, callback) {
        this.events.on(name, callback);
    }

    /**
     * Switch to a new video
     *
     * @param {Object} video - new video info
     * @param {Object} danmaku - new danmaku info
     */
    switchVideo(video, danmakuAPI) {
        this.pause();
        this.video.poster = video.pic ? video.pic : '';
        this.video.src = video.url;
        this.initMSE(this.video, video.type || 'auto');

        if (danmakuAPI) {
            this.template.danmakuLoading.style.display = 'block';
            this.bar.set('played', 0, 'width');
            this.bar.set('loaded', 0, 'width');
            this.template.ptime.innerHTML = '00:00';
            this.template.danmaku.innerHTML = '';
            if (this.danmaku) {
                this.danmaku.reload({
                    id: danmakuAPI.id,
                    address: danmakuAPI.api,
                    token: danmakuAPI.token,
                    maximum: danmakuAPI.maximum,
                    addition: danmakuAPI.addition,
                    user: danmakuAPI.user,
                });
            }
        }
    }

    initMSE(video, type) {
        this.type = type;
        if (this.options.video.customType && this.options.video.customType[type]) {
            if (Object.prototype.toString.call(this.options.video.customType[type]) === '[object Function]') {
                this.options.video.customType[type](this.video, this);
            } else {
                console.error(`Illegal customType: ${type}`);
            }
        } else {
            if (this.type === 'auto') {
                if (/m3u8(#|\?|$)/i.exec(video.src) || /max(#|\?|$)/i.exec(video.src) || /wmx(#|\?|$)/i.exec(video.src)) {
                    this.type = 'hls';
                } else if (/.flv(#|\?|$)/i.exec(video.src)) {
                    this.type = 'flv';
                } else if (/.mpd(#|\?|$)/i.exec(video.src)) {
                    this.type = 'dash';
                } else {
                    this.type = 'normal';
                }
            }

            switch (this.type) {
                // https://github.com/video-dev/hls.js
                case 'hls':
                    if (window.Hls) {
                        if (window.Hls.isSupported()) {
                            const options = this.options.pluginOptions.hls;
                            const hls = new window.Hls(options);
                            this.plugins.hls = hls;
                            hls.loadSource(video.src);
                            hls.attachMedia(video);

                            hls.on(window.Hls.Events.ERROR, (event, data) => {
                                console.log('HLS.Events.ERROR: ', event, data);
                                if (data.fatal) {
                                    switch (data.type) {
                                        case window.Hls.ErrorTypes.NETWORK_ERROR:
                                            hls.startLoad();
                                            break;
                                        case window.Hls.ErrorTypes.MEDIA_ERROR:
                                            hls.recoverMediaError();
                                            break;
                                        default:
                                            hls.destroy();
                                            break;
                                    }
                                } else if (data.details === 'internalException' && data.type === 'otherError') {
                                    // const level = last(this.getLevels());
                                    // hls.removeLevel(level?.level, level?.urlId);
                                    hls.startLoad();
                                } else {
                                    console.log("hls not fatal");
                                }
                            });

                            this.events.on('destroy', () => {
                                hls.destroy();
                                delete this.plugins.hls;
                            });
                        } else {
                            if (video.canPlayType('application/x-mpegURL') || video.canPlayType('application/vnd.apple.mpegURL')) {
                                this.type = 'normal';
                            } else {
                                this.notice('Error: Hls is not supported.');
                            }
                        }
                    } else {
                        this.notice("Error: Can't find Hls.");
                    }
                    break;

                // https://github.com/Bilibili/flv.js
                case 'flv':
                    if (window.flvjs) {
                        if (window.flvjs.isSupported()) {
                            const flvPlayer = window.flvjs.createPlayer(
                                Object.assign(this.options.pluginOptions.flv.mediaDataSource || {}, {
                                    type: 'flv',
                                    url: video.src,
                                }),
                                this.options.pluginOptions.flv.config
                            );
                            this.plugins.flvjs = flvPlayer;
                            flvPlayer.attachMediaElement(video);
                            flvPlayer.load();
                            this.events.on('destroy', () => {
                                flvPlayer.unload();
                                flvPlayer.detachMediaElement();
                                flvPlayer.destroy();
                                delete this.plugins.flvjs;
                            });
                        } else {
                            this.notice('Error: flvjs is not supported.');
                        }
                    } else {
                        this.notice("Error: Can't find flvjs.");
                    }
                    break;

                // https://github.com/Dash-Industry-Forum/dash.js
                case 'dash':
                    if (window.dashjs) {
                        const dashjsPlayer = window.dashjs.MediaPlayer().create().initialize(video, video.src, false);
                        const options = this.options.pluginOptions.dash;
                        dashjsPlayer.updateSettings(options);
                        this.plugins.dash = dashjsPlayer;
                        this.events.on('destroy', () => {
                            window.dashjs.MediaPlayer().reset();
                            delete this.plugins.dash;
                        });
                    } else {
                        this.notice("Error: Can't find dashjs.");
                    }
                    break;

                // https://github.com/webtorrent/webtorrent
                case 'webtorrent':
                    if (window.WebTorrent) {
                        if (window.WebTorrent.WEBRTC_SUPPORT) {
                            this.container.classList.add('dplayer-loading');
                            const options = this.options.pluginOptions.webtorrent;
                            const client = new window.WebTorrent(options);
                            this.plugins.webtorrent = client;
                            const torrentId = video.src;
                            video.src = '';
                            video.preload = 'metadata';
                            video.addEventListener('durationchange', () => this.container.classList.remove('dplayer-loading'), {once: true});
                            client.add(torrentId, (torrent) => {
                                const file = torrent.files.find((file) => file.name.endsWith('.mp4'));
                                file.renderTo(this.video, {
                                    autoplay: this.options.autoplay,
                                    controls: false,
                                });
                            });
                            this.events.on('destroy', () => {
                                client.remove(torrentId);
                                client.destroy();
                                delete this.plugins.webtorrent;
                            });
                        } else {
                            this.notice('Error: Webtorrent is not supported.');
                        }
                    } else {
                        this.notice("Error: Can't find Webtorrent.");
                    }
                    break;
            }
        }
    }

    initVideo(video, type) {
        this.initMSE(video, type);

        //Checking auto skip
        let shouldSkip = false;
        let isset = localStorage.getItem('is-auto-skip');
        if (isset && (isset === 'true' || isset === true)) {
            shouldSkip = true;
        }

        try {
            if (this.options.startTime == 0) {
                this.saveLog(true, true);
            }
        } catch (e) {
            console.log(e)
        }

        /**
         * video events
         */
        // show video time: the metadata has loaded or changed
        this.on('durationchange', () => {
            // compatibility: Android browsers will output 1 or Infinity at first
            if (video.duration !== 1 && video.duration !== Infinity) {
                this.template.dtime.innerHTML = utils.secondToTime(video.duration);
            }
        });

        // show video loaded bar: to inform interested parties of progress downloading the media
        this.on('progress', () => {
            const percentage = video.buffered.length ? video.buffered.end(video.buffered.length - 1) / video.duration : 0;
            this.bar.set('loaded', percentage, 'width');
        });

        // video download error: an error occurs
        this.on('error', (e) => {
            console.log("error:", this.video.error);
            console.log(e);

            if (!this.video.error) {
                // Not a video load error, may be poster load failed, see #307
                return;
            }
            this.tran && this.notice && this.type !== 'webtorrent' && this.notice(this.tran('Video load failed'), -1);
        });

        // video end
        this.on('ended', () => {
            this.bar.set('played', 1, 'width');
            if (!this.setting.loop) {
                this.pause();
                if (localStorage.getItem('is-auto-play') === 'true') {
                    if (this.options.next) {
                        this.destroy();
                        this.options.next.handler();
                    }
                }
            } else {
                this.seek(0);
                this.play();
            }
            if (this.danmaku) {
                this.danmaku.danIndex = 0;
            }
        });

        this.on('play', () => {
            if (this.paused) {
                this.play(true);
            }
        });

        this.on('pause', () => {
            if (!this.paused) {
                this.pause(true);
            }
        });

        this.on('timeupdate', () => {
            if (!this.isDragging) {
                this.bar.set('played', this.video.currentTime / this.video.duration, 'width');
            }

            const currentTime = utils.secondToTime(this.video.currentTime);
            if (this.template.ptime.innerHTML !== currentTime) {
                this.template.ptime.innerHTML = currentTime;
            }

            //Showing author info
            if (this.options.author) {
                if (this.video.currentTime > 2 && this.video.currentTime < 3) {
                    this.template.initAuthorInfo.style.opacity = 1;
                    setTimeout(() => {
                        this.template.initAuthorInfo.style.opacity = 0;
                    }, 3000)
                }
            }

            //Showing open and ending skip
            if (this.options.skipOp) {
                if (this.video.currentTime > this.options.skipOp.start && this.video.currentTime < this.options.skipOp.end && shouldSkip) {
                    this.template.skipOpBtn.click();
                }

                if (this.video.currentTime > this.options.skipOp.start && this.video.currentTime < this.options.skipOp.end) {
                    this.template.skipOpBtn.style.display = "flex";
                    this.template.skipOpBtn.style.opacity = 1;
                }

                if (this.video.currentTime > this.options.skipOp.end || this.video.currentTime < this.options.skipOp.start) {
                    this.template.skipOpBtn.style.display = "none";
                    this.template.skipOpBtn.style.opacity = 0;
                }
            }

            if (this.options.skipEd) {
                if (this.video.currentTime > this.options.skipEd.start && this.video.currentTime < this.options.skipEd.end && shouldSkip) {
                    this.template.skipEdBtn.click();
                }

                if (this.video.currentTime > this.options.skipEd.start && this.video.currentTime < this.options.skipEd.end) {
                    this.template.skipEdBtn.style.display = "flex";
                    this.template.skipEdBtn.style.opacity = 1;
                }

                if (this.video.currentTime > this.options.skipEd.end || this.video.currentTime < this.options.skipEd.start) {
                    this.template.skipEdBtn.style.display = "none";
                    this.template.skipEdBtn.style.opacity = 0;
                }
            }

            try {
                this.saveLog(false, false);
            } catch (e) {
                console.log(e)
            }
        });

        for (let i = 0; i < this.events.videoEvents.length; i++) {
            video.addEventListener(this.events.videoEvents[i], () => {
                this.events.trigger(this.events.videoEvents[i]);
            });
        }

        this.volume(this.user.get('volume'), true, true);

        if (this.options.subtitle) {
            this.subtitle = new Subtitle(this.template.subtitle, this.video, this.options.subtitle, this.options.stamp, this.events);
            if (!this.user.get('subtitle')) {
                this.subtitle.hide();
            }
        }

        if (this.options.startTime && this.options.startTime > 0) {
            this.seek(this.options.startTime, true, true);
        }
    }

    saveLog(isForce = false, initDuration = false) {
        if (isForce) {
            updateTime = parseInt(this.video.currentTime);
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    media: this.options.media,
                    episode: this.options.episode,
                    user: this.options.userId,
                    time: initDuration ? 1 : updateTime,
                    duration: initDuration ? 1400 : parseInt(this.video.duration)
                })
            }

            if (this.options.userId && this.options.media && this.options.episode) {
                try {
                    fetch(this.options.historyUrl, options).then(o => {
                        console.log(o);
                    }).catch(e => {
                        console.error(e)
                    })
                } catch (e) {
                    console.error(e);
                }
            }
        } else {
            updateTime = parseInt(this.video.currentTime);
            let options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    media: this.options.media,
                    episode: this.options.episode,
                    user: this.options.userId,
                    time: updateTime == 0 ? 1 : updateTime,
                    duration: parseInt(this.video.duration)
                })
            }

            if (this.options.userId && this.options.media && this.options.episode) {
                if (updateTime % 10 === 0 && lastSentTime !== updateTime) {
                    lastSentTime = updateTime;
                    try {
                        fetch(this.options.historyUrl, options).then(o => {
                            console.log(o);
                        }).catch(e => {
                            console.error(e)
                        })
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }

    switchQuality(index) {
        index = typeof index === 'string' ? parseInt(index) : index;
        if (this.qualityIndex === index || this.switchingQuality) {
            return;
        } else {
            this.qualityIndex = index;
        }
        this.switchingQuality = true;
        this.quality = this.options.video.quality[index];
        this.template.currentQuality.innerHTML = this.quality.name;

        const paused = this.video.paused;
        this.video.pause();
        this.sound.audioPause();

        console.log("audio file:", this.options.audio);
        console.log("sub file:", this.options.subtitle);

        const videoHTML = tplVideo({
            current: false,
            pic: null,
            screenshot: this.options.screenshot,
            preload: 'auto',
            url: this.quality.url,
            subtitle: this.options.subtitle,
            audio: this.options.audio
        });

        const videoEle = new DOMParser().parseFromString(videoHTML, 'text/html').body.firstChild;
        this.template.videoWrapInner.insertBefore(videoEle, this.template.videoWrapInner.getElementsByTagName('div')[0]);
        this.prevVideo = this.video;
        this.video = videoEle;
        this.initVideo(this.video, this.quality.type || this.options.video.type);
        this.seek(this.prevVideo.currentTime);
        this.sound.seekAudio(this.prevVideo.currentTime);

        this.container.classList.add('dplayer-playing');
        // this.notice(`${this.tran('Switching to')} ${this.quality.name} ${this.tran('quality')}`, -1);

        this.events.trigger('quality_start', this.quality);
        this.container.classList.remove('dplayer-playing');

        this.on('canplay', () => {
            if (this.prevVideo) {
                if (this.video.currentTime !== this.prevVideo.currentTime) {
                    this.seek(this.prevVideo.currentTime);
                    this.sound.seekAudio(this.prevVideo.currentTime);
                    return;
                }

                this.template.videoWrapInner.removeChild(this.prevVideo);
                this.video.classList.add('dplayer-video-current');
                if (!paused) {
                    this.video.play();
                    this.sound.audioPlay();
                }

                // console.log("after switch: ", this.template.videoWrapInner);
                this.prevVideo = null;
                this.notice(`Бичлэгийн чанар: ${this.quality.name}`);
                this.switchingQuality = false;

                this.events.trigger('quality_end');
                this.sound.audioPlay();
                localStorage.setItem('quality', index);
            }
        });
    }

    notice(text, time = 2000, opacity = 0.8) {
        this.template.notice.innerHTML = text;
        this.template.notice.style.opacity = opacity;
        if (this.noticeTime) {
            clearTimeout(this.noticeTime);
        }
        this.events.trigger('notice_show', text);
        if (time > 0) {
            this.noticeTime = setTimeout(() => {
                this.template.notice.style.opacity = 0;
                this.events.trigger('notice_hide');
            }, time);
        }
    }

    resize() {
        if (this.danmaku) {
            this.danmaku.resize();
        }
        if (this.controller.thumbnails) {
            this.controller.thumbnails.resize(160, (this.video.videoHeight / this.video.videoWidth) * 160, this.template.barWrap.offsetWidth);
        }
        this.events.trigger('resize');
    }

    speed(rate) {
        localStorage.setItem('speed', rate);
        this.video.playbackRate = rate;
        this.sound.audioPlaybackSpeed(rate);
        this.template.currentSpeed.innerHTML = `x${rate}`;
    }

    destroy() {
        instances.splice(instances.indexOf(this), 1);
        this.pause();
        this.controller.destroy();
        this.timer.destroy();
        this.video.src = '';
        this.template.audio.src = '';
        this.container.innerHTML = '';
        this.events.trigger('destroy');
    }

    static get version() {
        return "3.6";
    }
}

export default MaxPlayer;

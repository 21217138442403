import utils from "./utils";

class Thumbnails {
    constructor(options) {
        this.container = options.container;
        this.barWidth = options.barWidth;
        this.container.style.backgroundImage = `url('${options.url}')`;
        this.events = options.events;
        this.container.style.display = 'none';

        this.image = new Image();
        this.image.src = options.url;
        // console.log("bar w: ", this.barWidth);

    }

    resize(width, height, barWrapWidth) {
        this.container.style.width = `${width}px`;
        this.container.style.height = `${height}px`;
        this.container.style.top = `${-height - 35}px`;

        if(utils.isMobile){
            this.container.style.top = `${-height-1}px`;
            this.container.style.transform = 'scale(0.75)';
        }
        this.barWidth = barWrapWidth;
    }

    show() {
        this.container.style.display = 'block';
        this.events && this.events.trigger('thumbnails_show');
    }

    move(position) {
        let countThumb = this.image.width / 160;
        this.container.style.backgroundPosition = `-${(Math.ceil((position / this.barWidth) * countThumb) - 1) * 160}px 0`;
        this.container.style.backgroundSize = `cover`;
        this.container.style.left = `${Math.min(Math.max(position - this.container.offsetWidth / 2, -10), this.barWidth - 150)}px`;
    }

    hide() {
        this.container.style.display = 'none';
        this.events && this.events.trigger('thumbnails_hide');
    }
}

export default Thumbnails;

import api from "../_boot/baseApi";

export const codeSendPhone = (context, payload) => {
  let vm = payload.vm;

  if (vm.phone.length === 8) {
    vm.isLoading = true;
    // let url = this.$verType === 'register' ?  `/api/m/sms/vp/${this.userId}/${this.phone}?vp=register` : `/api/m/sms/vp/${this.userId}/${this.phone}/0/0?vp=pass`
    // api.get(`/api/m/phone/check/${vm.phone}`).then(({data}) => {
    api.get(`/api/m/sms/vp/0/${vm.phone}?vp=register`).then(({ data }) => {
      if (data.status) {
        vm.isLoading = false;
        vm.step = 2;
      } else {
        vm.isLoading = false;
        vm.$toast.error(data.msg);
        // vm.$toast.add({severity: 'error', summary: 'Алдаа', detail: data.msg, life: 3000, group: 'bc'});
      }
    });
  } else {
    vm.$toast.error("Утасны дугаараа зөв орууна уу!");
    // vm.$toast.error({severity: 'info', summary: 'Алдаа', detail: 'Утасны дугаараа зөв орууна уу!', life: 3000});
  }
};

export const checkPhone = (context, payload) => {
  let vm = payload.vm;

  if (vm.phone.length === 8) {
    vm.isLoading = true;
    api.get(`/api/m/phone/check/${vm.phone}?type=check`).then(({ data }) => {
      if (data.status) {
        vm.isLoading = false;
        vm.step = 6;
      } else {
        vm.isLoading = false;
        vm.$toast.error(data.msg);
        // vm.$toast.add({severity: 'error', summary: 'Алдаа', detail: data.msg, life: 3000, group: 'bc'});
      }
    });
  } else {
    vm.$toast.error("Утасны дугаараа зөв орууна уу!");
  }
};

export const checkCode = (context, payload) => {
  let vm = payload.vm;

  vm.isLoadingCode = true;
  // api.get(`/api/m/sms/vp/0/${vm.phone}/${vm.verify}?type=register`)
  api
    .get(`/api/m/sms/verify/0/${vm.phone}/${vm.verify}?type=register`)
    .then(({ data }) => {
      if (data.status) {
        vm.isLoadingCode = false;
        vm.step = 3;
      } else {
        vm.isLoadingCode = false;
        vm.$toast.error(data.msg);
      }
    })
    .catch((err) => {
      console.log(err.response);
      vm.isLoadingCode = false;
      vm.$toast.error(
        err.response.data.msg != null
          ? err.response.data.msg
          : "Пин код буруу байна!"
      );
    });
};

export const sendPass = (context, payload) => {
  let vm = payload.vm;

  vm.isLoadingPin = true;
  api
    .get(`/api/m/pin/set/${vm.phone}/${vm.password}/${vm.verify}/register`)
    .then(({ data }) => {
      if (data.status) {
        vm.isLoadingPin = false;
        vm.step = 4;
      } else {
        vm.isLoadingPin = false;
        vm.$toast.error(data.msg);
      }
    })
    .catch((err) => {
      console.log(err.response);
      vm.isLoadingPin = false;
      vm.$toast.error(
        err.response.data.msg != null
          ? err.response.data.msg
          : "Алдаа гарлаа байна!"
      );
    });
};

export const sendEmail = (context, payload) => {
  let vm = payload.vm;

  vm.isLoadingEmail = true;
  const params = {
    email: vm.email,
  };
  console.log("email");
  console.log(vm.email);
  api
    .get(`/api/m/seeroo/send/email/verify`, { params })
    .then(({ data }) => {
      if (data.status) {
        vm.isLoadingEmail = false;
        vm.step = 5;
      } else {
        vm.isLoadingEmail = false;
        vm.$toast.error(data.msg);
      }
    })
    .catch((err) => {
      console.log(err.response);
      vm.isLoadingEmail = false;
      vm.$toast.error(
        err.response.data.msg != null
          ? err.response.data.msg
          : "Имэйл буруу байна!"
      );
    });
};

export const checkEmailCode = (context, payload) => {
  let vm = payload.vm;

  vm.isLoadingEmailCode = true;
  api
    .post(`/api/m/seeroo/email/verify/${vm.phone}/${vm.email}/${vm.code}`, {})
    .then(({ data }) => {
      if (data.status) {
        vm.isLoadingEmailCode = false;
        vm.step = 7;
        vm.userId = data.data.user;
        vm.termsLink = data.data.url;
        // localStorage.setItem("token", data.data.access_token);
        // commit("setUser", data.data);
        // commit('setUserLoading', false);
        // document.location = "#";
      } else {
        vm.isLoadingEmailCode = false;
        vm.$toast.error(data.msg);
      }
    })
    .catch((err) => {
      console.log(err.response);
      vm.isLoadingEmailCode = false;
      vm.$toast.error(
        err.response.data.msg != null
          ? err.response.data.msg
          : "Код буруу байна!"
      );
    });
};

export const login = ({ commit }, payload) => {
  let vm = payload.vm;
  vm.isLoadingVerify = true;
  commit("setUserLoading", true);

  // api.post("/api/m/pin/login", {
  //     phone: vm.phone,
  //     password: vm.verify,
  //     device: 'web'
  // })
  // api.post("/api/m/pin/login", {
  //     phone: vm.phone,
  //     password: vm.verify,
  //     pin: vm.pin,
  // })

  console.log("phone and pass");
  console.log(vm.phone);
  console.log(vm.password);

  api
    .post("/api/m/login", {
      phone: vm.phone,
      password: vm.password,
    })
    .then(({ data }) => {
      if (data.status) {
        vm.isLoadingVerify = false;
        localStorage.setItem("token", data.data.access_token);
        localStorage.setItem("user_id", data.data.id);
        commit("setUser", data.data);
        commit("setUserLoading", false);
        document.location = "#";
        vm.hide();
      } else {
        vm.isLoadingVerify = false;
        vm.$toast.error(data.msg);
      }
    })
    .catch((err) => {
      console.log(err.response);
      vm.isLoadingVerify = false;
      vm.$toast.error(
        err.response.data.msg != null
          ? err.response.data.msg
          : "Пин код буруу байна!"
      );
    });
};

export const getUser = async ({ commit }) => {
  commit("setUserLoading", true);
  if (localStorage.getItem("token") && localStorage.getItem("user_id")) {
    let uId = localStorage.getItem("user_id");
    api.get(`/api/m/auth/me/${uId}`).then(({ data }) => {
      commit("setUser", data);
      commit("setUserLoading", false);
    });
  } else {
    commit("setUserLoading", false);
  }
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user_id");
  document.location = "/user/logout";
};

export const saveUserHHA = ({ commit }, payload) => {
  let vm = payload.vm;
  if (vm.lastName.length === 0) {
    vm.$toast.error("Овогоо оруулна уу!");
  } else if (vm.firstName.length === 0) {
    vm.$toast.error("Нэрээ оруулна уу!");
  } else if (vm.termsCheck === false) {
    vm.$toast.error("Үлчилгээний нөхцөлийг зөвшөөрнө үү!");
  } else {
    vm.isLoading = true;
    api
      .get(`/api/m/seeroo/set/register/data`, {
        params: {
          user_id: vm.userId,
          lastname: vm.lastname,
          firstname: vm.firstname,
        },
      })
      .then(({ data }) => {
        if (data.status) {
          console.log("end ----", data);
          login({ commit }, payload);
        } else {
          vm.isLoading = false;
          vm.$toast.error(data.msg);
        }
      });
  }
};

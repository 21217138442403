/*
W3C def language codes is :
    language-code = primary-code ( "-" subcode )
        primary-code    ISO 639-1   ( the names of language with 2 code )
        subcode         ISO 3166    ( the names of countries )

NOTE: use lowercase to prevent case typo from user!
Use this as shown below..... */

function i18n(lang) {
    this.lang = lang;
    this.tran = (text) => {
        if (tranTxt[this.lang] && tranTxt[this.lang][text]) {
            return tranTxt[this.lang][text];
        } else {
            return text;
        }
    };
}

// add translation text here
const tranTxt = {
    'mn': {
        'Danmaku is loading': '弹幕加载中',
        Top: '顶部',
        Bottom: '底部',
        Rolling: '滚动',
        'Input danmaku, hit Enter': '输入弹幕，回车发送',
        'About author': 'Эзэмшигчийн тухай',
        'DPlayer feedback': '播放器意见反馈',
        'About DPlayer': 'Max Player',
        Loop: 'Давтаж тоглуулах',
        Speed: 'Тоглуулах хурд',
        'Opacity for danmaku': '弹幕透明度',
        Normal: '正常',
        'Please input danmaku content!': '要输入弹幕内容啊喂！',
        'Set danmaku color': '设置弹幕颜色',
        'Set danmaku type': '设置弹幕类型',
        'Show danmaku': '显示弹幕',
        'Video load failed': 'Бичлэг тоглуулахад алдаа гарлаа',
        'Danmaku load failed': '弹幕加载失败',
        'Danmaku send failed': '弹幕发送失败',
        'Switching to': '正在切换至',
        'Switched to': '已经切换至',
        quality: 'Бичлэгийн чанар',
        FF: 'Урагш гүйлгэх',
        REW: 'Ухрааж гүйлгэх',
        'Unlimited danmaku': '海量弹幕',
        'Send danmaku': '发送弹幕',
        Setting: 'Тохиргоо',
        'Full screen': 'Дэлгэц дүүргэх',
        'Web full screen': '页面全屏',
        Send: '发送',
        Screenshot: '截图',
        AirPlay: '无线投屏',
        s: '秒',
        'Show subtitle': 'Subtitle гаргах',
        'Hide subtitle': 'Subtitle нуух',
        Volume: '音量',
        Live: 'Live',
        'Video info': 'Бичлэгийн мэдээлэл',
    }
};

export default i18n;

<template>
  <skeleton>
    <div class="w-full aspect-16-9"></div>
    <div class="w-full h-60"></div>
  </skeleton>
</template>

<script>
import Skeleton from "../components/Skeleton/index.vue";

export default {
  components: {
    Skeleton,
  },
};
</script>

<style>
</style>
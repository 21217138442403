<template>
  <div class="age-media-list media-list">
    <div v-if="isLoading" class="media-loader-wrap">
      <div class="seeroo-loader">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

    <div v-else class="media-grid-wrapper">
      <div class="seeroo-grid grid gap-4 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-2 cs-style-3">
        <div v-for="item in mediaList" :key="item.id" class="media-item">
          <router-link :to="`/theatre/detail/${item.id}`" class="media-cover">
            <a href="javascript:void(0)" class="card__play">
              <i class="isax isax-play"></i>
            </a>
          </router-link>

          <span v-if="item.is_free" class="card__rate card__rate--green price">Үнэгүй</span>

          <img :src="Poster" v-lazy:background-image="imageUrl(item.poster_hz, true)"
               class="poster rounded-md"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {imageUrl} from "../../utils";
import Poster from "../../assets/poster-hz.png";
import api from "../../store/_boot/baseApi"
import {setModalActive} from "../../store";

export default {
  components: {},
  setup() {

  },
  data() {
    return {
      isLoading: true,
      mediaList: [],
      Poster
    }
  },

  computed: {},

  created() {
    this.getAgeMedias();
  },

  watch: {},

  methods: {
    imageUrl,

    getAgeMedias() {
      this.isLoading = true;
      api.get('/api/m/age/media/' + this.$route.params.age).then(({data}) => {
        this.mediaList = data;
        this.isLoading = false;
      })
    },

    handleClick(animeID, epID) {
      if (this.mirror) {
        this.$router.push(`/watch/${animeID}/${epID}`)
      } else {
        this.$router.replace({query: {t: this.$route.query.t, media: animeID}});
        setModalActive(true);
      }
    },
  }
};
</script>
<style>
</style>
<template>
  <div class="page">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3>Түгээмэл асуулт, хариулт</h3>
          <span class="divider"></span>
          <!--          <p>Насны ангилалаар үзэх</p>-->
        </div>

        <div class="header-breadcrumb">
          <router-link to="/">
            <span>Эхлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link to="" class="current">
            <span>Түгээмэл асуулт, хариулт</span>
          </router-link>
        </div>

      </div>

    </div>
    <div v-if="faq.length > 0">
      <div
          v-for="(item, index) in faq"
          :key="index"
          @click="toggleAccordion(index)"
          :class="{ 'accordion-item': true, 'open': activeIndex === index }"
      >
        <div class="accordion-title">{{ item.title }}
          <i :class="{'isax isax-arrow-down-1':true, 'isax isax-arrow-up-2':activeIndex === index}"></i>
        </div>
        <div v-show="activeIndex === index" class="accordion-content">
          <p v-html="item.body"></p>
        </div>
      </div>
    </div>
  </div>
  <div>

  </div>
</template>

<script>
import axios from "axios";
import config from "@store/_boot/config";

export default {
  data() {
    return {
      faq: [],
      activeIndex: 0,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      axios.get(`${config.HOST}/api/footer/get-faq`).then(({data}) => {
        if (data.status) {
          this.faq = data.data;
          console.log("faq----->", this.faq)
        }
      })
    },
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null; // Collapse if clicked on already open item
      } else {
        this.activeIndex = index; // Expand clicked item
      }
    }
  }
};
</script>

<style scoped>
.accordion-item {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  cursor: pointer;
  margin-top: 40px;
  width: auto;
  margin-left: 300px;
  margin-right: 300px
}

.accordion-title {
  padding: 10px;
  background-color: transparent;
  display:flex;
  flex-direction:row;
  justify-content:space-between;
}

.accordion-content {
  padding: 10px;
  p{
    font-size:14px;
  }
}

.accordion-item.open .accordion-title {
  color:black;
  font-weight:bold;
  font-size:16px;
  border-bottom: 1px solid black;
}

.accordion-item.open{
  border-bottom: none !important;
}

@media only screen and (max-width: 1100px) {
  .accordion-item {
    margin-left: 100px;
    margin-right: 100px
  }
}

@media only screen and (max-width: 600px) {
  .accordion-item {
    margin-left: 10px;
    margin-right: 10px
  }
}
</style>
<template>
    <ads-player v-if="hasVast" :options="options" :skipVast="skipVast"/>
    <div v-else class="dplayer"></div>
</template>

<script>
import DPlayer from "./js/player";
import AdsPlayer from "./AdsPlayer";

export default {
    name: "Player",
    props: {
        options: {
            type: Object
        }
    },
    components: {
        AdsPlayer
    },
    data() {
        return {
            dataOptions: this.options,
            dp: null,
            hasVast: false,
            isLoadedVast: false,
            timer: null
        }
    },

    mounted() {
        if (this.options.vast != null && typeof this.options.vast !== "undefined") {
            this.hasVast = true;
        } else {
            this.initVideoPlayer();
        }
    },

    methods: {
        initVideoPlayer() {
            this.dataOptions.container = this.$el
            const player = this.dp = new DPlayer(this.dataOptions)
            const events = player.events
            Object.keys(events).forEach(item => {
                if (item === 'events') {
                    return false
                } else {
                    events[item].forEach(event => {
                        player.on(event, () => this.$emit(event))
                    })
                }
            })
            player.play();
        },

        skipVast() {
            this.hasVast = false;
            this.$nextTick(() => {
                this.initVideoPlayer();
            })
        }
    }
}
</script>

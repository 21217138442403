<template>
  <div
      class="
      z-[600]
      bg-black bg-opacity-60
      top-0
      fixed
      h-screen
      w-full
      overflow-y-scroll
    "
      @click.self="handleCloseClick"
  >
    <div class="info-container bg-background seeroo-detail">
      <details-modal-skeleton v-if="isLoading"/>

      <div v-else>
        <div class="relative w-full h-[30rem]">
          <div v-if="isHoverVideo && hoverVideoPath != null" class="hover-video details">
            <video-background :src="hoverVideoPath"/>
          </div>
          <Image
              v-else
              :src="media.image1"
              alt="info banner"
              class="w-full h-full object-cover"
          />

          <router-link to="#" v-tooltip.bottom="'Орчуулагч'" class="mr-2 mb-2 z-50 absolute top-6 left-14">
            <Chip v-if="media.translater != null" :label="media.translater.nickname"
                  :image="imageUrl(media.translater.avatar)" class="custom-chip"/>
          </router-link>

          <circle-button
              class="z-50 absolute top-5 right-5 bg-background text-white w-[40px] h-[40px] close-btn"
              @click="handleCloseClick"
          >
            <IconCross class="text-lg"/>
          </circle-button>

          <div class="info__overlay">
            <div class="overlay-items">
              <div class="flex items-center space-x-2 media-actions">

                <a href="javascript:void(0)"
                   v-if="(media.epId != false && (!media.is_mirror && !media.is_blacklist)) || mirror"
                   @click="gotoWatch(media.id, media.epId)"
                   v-tooltip.top="'Цуврал үзэх'">
                  <circle-button class="bg-white text-black border-white hover:bg-gray-300 w-[40px] h-[40px]">
                    <IconPlayFill class="text-lg"/>
                  </circle-button>
                </a>

                <!--                <div v-if="media.later == 1 || media.later == '1'" v-tooltip.top="'Жагсаалтад хасах'">-->
                <!--                  <circle-button class="w-[40px] h-[40px] action-btn-active" @click="setMediaStatus('later', 0)">-->
                <!--                    <IconPlus class="text-lg"/>-->
                <!--                  </circle-button>-->
                <!--                </div>-->

                <!--                <div v-else v-tooltip.top="'Жагсаалтаас үзэх'">-->
                <!--                  <circle-button class="w-[40px] h-[40px]" @click="setMediaStatus('later', 1)">-->
                <!--                    <IconPlus class="text-lg"/>-->
                <!--                  </circle-button>-->
                <!--                </div>-->

                <!-- Like action -->
                <div v-if="media.liked == 1 || media.liked == '1'" v-tooltip.top="'Таалагдсан хэсгээс хасах'">
                  <circle-button class="w-[40px] h-[40px] action-btn-active" @click="setMediaStatus('like', 0)">
                    <Heart class="text-lg"/>
                  </circle-button>
                </div>

                <div v-else v-tooltip.top="'Таалагдлаа'">
                  <circle-button class="w-[40px] h-[40px]" @click="setMediaStatus('like', 1)">
                    <Heart class="text-lg text-red"/>
                  </circle-button>
                </div>
              </div>

              <div v-if="media.logo != null" class="media-logo">
                <Image
                    :src="media.logo"
                    alt="info banner"
                />
              </div>
              <h1 v-else class="media-modal-title text-2xl mb-6 detail-title">
                {{ media.title }}
              </h1>
            </div>
          </div>
        </div>

        <div class="space-y-8 px-4 md:px-12 my-3">
          <div class="md:space-x-8 flex flex-col md:flex-row space-y-4">
            <div class="flex-1 flex-grow-[2] space-y-4">
              <!--              <p class="text-base text-gray-300">{{ data.details.tagline }}</p>-->

              <div class="flex items-center space-x-2">
<!--                <div class="flex items-center text-yellow-500">-->
<!--                  <IconStar/>-->
<!--                  <p>{{ media.rating ? media.rating : 0 }}/10</p>-->
<!--                </div>-->

<!--                <p class="border border-gray-500 px-2 detail-age">-->
<!--                  {{ media.age_limit ? "+" + media.age_limit : 'N/A' }}-->
<!--                </p>-->

                <p class="detail-title">
                  {{ media.original_name }}
                </p>
              </div>

              <p class="text-base txt-desc" v-html="media.description"></p>
            </div>

            <div class="mt-4 md:mt-0 pt-7 flex-1 space-y-2 text-sm">
              <!--              <div class="space-x-2">-->
              <!--                <span class="text-gray-400">Оригинал нэр:</span>-->
              <!--                <span class="text-white">-->
              <!--                  {{ media.original_name }}-->
              <!--                </span>-->
              <!--              </div>-->

              <!--              <div class="space-x-2">-->
              <!--                <span class="text-gray-400">Эх зохиол:</span>-->
              <!--                <span class="text-white">-->
              <!--                 {{ media.bases ? media.bases : 'N/A' }}-->
              <!--                </span>-->
              <!--              </div>-->

<!--              <div class="space-x-2">-->
<!--                <span class="text-gray-400">Төлөв:</span>-->
<!--                <span class="text-white">-->
<!--                 {{ media.is_completed ? "Гарч дууссан" : "Гарч байгаа" }}-->
<!--                </span>-->
<!--              </div>-->

<!--              <div class="space-x-2">-->
<!--                <span class="text-gray-400">Гарсан жил:</span>-->
<!--                <span class="text-white">-->
<!--                 {{ media.released_date ? media.released_date : 'N/A' }}-->
<!--                </span>-->
<!--              </div>-->

<!--              <div class="space-x-2">-->
<!--                <span class="text-gray-400">Нийт анги:</span>-->
<!--                <span class="text-white">-->
<!--                 {{ media.total_episode ? media.total_episode : 'N/A' }}-->
<!--                </span>-->
<!--              </div>-->

              <div class="space-x-2">
                <span class="text-gray-400">Ангилал:</span>
                <router-link v-for="item in media.categories"
                             :key="item.id"
                             :to="'/series/'+item.id+'/all/all/all'"
                             @click="handleCloseClick()">
                  <Tag class="ep-tag" :value="item.title"></Tag>
                </router-link>
              </div>

              <div class="space-x-2">
                <span class="text-gray-400">Насны ангилал:</span>
                <Tag class="ep-tag" :value="'+' + media.age_limit"></Tag>
              </div>
            </div>
          </div>

          <Divider/>

          <!--Director and Studio-->
          <div v-if="media.other_seasons != null" class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
            <div>
              <h1 class="text-2xl mb-5">Улирал</h1>
              <a href="javascript:void(0)" @click="gotoSeasan(item.id)" v-for="item in media.other_seasons" :key="item.id"
                 :class="`rounded p-2 text-center bg-[#e5e6e7] border text-sm border-transparent ml-1 ep-link ${item.id == activeSeasan ? 'active' : ''}`">
                {{ item.title }}
              </a>
            </div>
          </div>
          <div class="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-4">
            <div>
              <h1 class="text-2xl mb-5">Анги</h1>
              <div v-if="isSectionLoading" class="flex justify-center">
                <ProgressSpinner style="width:50px;height:50px" strokeWidth="8"/>
              </div>
              <div v-else class="grid grid-cols-10 sm:grid-cols-10 md:grid-cols-10 lg:grid-cols-10 justify-center gap-2 my-1">
                <a href="javascript:void(0)" v-for="item in episodes" :key="item.id" @click="gotoWatch(item.anime_id, item.id)"
                   :class="`rounded p-3 text-center bg-[#e5e6e7] border border-transparent ep-link hover:bg-[#FA1400]`">
                  {{ item.number }}
                </a>
              </div>
            </div>
          </div>

          <!--Director and Studio-->
          <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
            <div v-if="media != null && media.studio != null">
              <h1 class="text-2xl mb-5">Студи</h1>
              <director-card
                  class="col-span-1"
                  :data="media.studio"
              />
            </div>

            <div v-if="media != null && media.director != null">
              <h1 class="text-2xl mb-5">Найруулагч</h1>
              <director-card
                  class="col-span-1"
                  :data="media.director"
              />
            </div>
          </div>

          <!--Actors-->
          <div v-if="media != null && media.actors != null">
            <h1 class="text-2xl mb-5">Дүрүүд</h1>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
              <actor-card
                  class="col-span-1"
                  v-for="item in media.actors"
                  :data="item"
                  :key="item.id"
              />
            </div>
          </div>

          <!--Related-->
          <div v-if="media != null && media.related != null">
            <h1 class="text-2xl mb-5">Төстэй цувралууд</h1>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <poster-card
                  class="col-span-1"
                  v-for="item in media.related"
                  :data="item"
                  :key="item.id"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import IconPlayFill from "~icons/ph/play-fill";
import IconCross from "~icons/akar-icons/cross";
// import IconStar from "~icons/ic/sharp-star-purple500";
import Heart from "~icons/fluent/heart-20-regular";
import PosterCard from "./../PosterCard.vue";
import ActorCard from "@components/AnimeActorCard.vue";
import DirectorCard from "@components/AnimeDirectorCard.vue";
import CircleButton from "./../CircleButton.vue";
import Image from "./../Image.vue";
import {imageUrl} from "../../utils";
import config from "../../store/_boot/config";

import DetailsModalSkeleton from "../../skeletons/DetailsModalSkeleton.vue";

import {setModalActive} from "../../store";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    Image,
    CircleButton,
    IconPlayFill,
    IconCross,
    // IconStar,
    PosterCard,
    DetailsModalSkeleton,
    Heart,
    ActorCard,
    DirectorCard,
  },

  computed: {
    ...mapGetters(['episode', 'media', 'backLink', 'canWatch', 'isFree', 'warnMsg', 'user', 'mirror']),
  },

  created() {
    this.isLoading = true;
    if (this.$route.query.media) {
      this.getMediaDetail({vm: this, mediaID: this.$route.query.media});
      console.log('id');
      this.gotoSeasan(1);
    }
  },

  mounted() {
    setTimeout(() => {
      axios.get(`${config.HOST}/api/m/anime/hover/${this.media.id}`).then(({data}) => {
        if (data.status) {
          this.isHoverVideo = true;
          this.hoverVideoPath = data.p;
        }
      })
    }, 1500)
  },

  beforeMount() {
    const body = document.querySelector("body");
    body.classList.add('media-open');
  },

  beforeUnmount() {
    this.$store.dispatch('clearMedia');
    const body = document.querySelector("body");
    // this.$router.replace({'query': {t: this.$route.query.t, media: 'c'}});
    body.classList.remove('media-open');
  },

  watch: {
    '$route.query.media'() {
      this.isLoading = true;
      this.getMediaDetail({vm: this, mediaID: this.$route.query.media})
    }
  },

  data() {
    return {
      isSectionLoading: false,
      isLoading: true,
      isVisible: false,
      isHoverVideo: false,
      hoverVideoPath: false,
      activeSeasan: 1,
      episodes: null
    }
  },

  methods: {
    imageUrl,
    ...mapActions(['getEpisodeDetail', 'reportEpisode', 'getMediaDetail']),

    handleCloseClick() {
      setModalActive(false);
    },

    gotoWatch(animeID, epID) {
      // window.location = `/watch/${animeID}/${epID}`;
      // setModalActive(false);
      this.$router.push(`/watch/${animeID}/${epID}`)
    },

    gotoSeasan(animeID) {
      this.isSectionLoading = true;
      axios.get(`${config.HOST}/api/m/episodes/${animeID}`).then(({data}) => {
        this.isSectionLoading = false;
        this.episodes = data;
        this.activeSeasan = animeID;
      });
    },

    setMediaStatus(action, value) {
      if (this.user == null) {
        this.$toast.error("Нэвтэрч орсны дараа уг үйлдлийг хийх боломжтой");
        return;
      }
      this.$store.dispatch('setStatus', {vm: this, action, value})
    }
  },
};
</script>

<style scoped>
.info-container {
  @apply bg-background md:rounded-xl lg:w-[75vw] md:w-[80vw] w-full max-w-[54rem] min-h-screen md:mt-[2rem] shadow overflow-hidden mx-auto;
  -webkit-box-shadow: 0px 0px 12px 0px #000000;
  box-shadow: 0px 0px 12px 0px #000000;
}

.info__overlay {
  @apply p-4 md:p-12 absolute top-0 inset-0 flex flex-col justify-end space-y-2;
  background: linear-gradient(rgba(12, 18, 26, 0), rgba(12, 18, 26, 1));
  /*background: linear-gradient(rgba(241, 242, 243, 0), rgba(241, 242, 243, 1));*/
}
</style>
<style lang="scss" scoped>
.p-chip.custom-chip {
  background: rgba(255, 255, 255, 0.4);
  color: black;
  font-size: 14px;
}

.p-chip.custom-chip.p-chip-text {
  padding: 0 10px !important;
}
</style>
<template>
  <footer class="footer seeroo-footer">

    <img :src="imageUrl(footer_image)" alt="logo" class="footer-image"/>

    <div class="footer-inner">
      <div class="footer-links">
        <h3>Холбоосууд</h3>
        <ul class="footer-text">
          <li>
            <router-link to="/content/47" class="link link-hover">Бидний тухай</router-link>
          </li>
          <!--          <li><router-link to="/content/45" class="link link-hover">Оюуны өмч</router-link></li>-->
          <li>
            <router-link to="/content/48" class="link link-hover">Үйлчилгээний нөхцөл</router-link>
          </li>
          <li>
            <router-link to="/content/49" class="link link-hover">Нууцлалын бодлого</router-link>
          </li>
          <li>
            <router-link to="/faq" class="link link-hover">Түгээмэл асуулт, хариулт</router-link>
          </li>
          <li>
            <router-link to="/news" class="link link-hover">Мэдээ, мэдээлэл</router-link>
          </li>
        </ul>
      </div>

      <div class="footer-socials">
        <h3>Холбоо барих</h3>
        <div v-if="contact.length > 0">
          <ul class="footer-contact" v-for="item in contact" :key="item.id">
            <li v-if="item.title === 'Email'" style="display:flex; flex-direction:row;align-items:center">
              <a class="email-click" :href="'mailto:'+item.value+'?subject=your title&body=TThe message'">
                <img
                    style="height: 36px; width:36px; color:yellow; margin-right:14px" :src="imageUrl(item.icon)"
                    alt="icon"/>{{ item.value }}
              </a>

            </li>
            <li v-else style="display:flex; flex-direction:row;align-items:center"><img
                style="height: 36px; width:36px; color:yellow; margin-right:14px" :src="imageUrl(item.icon)"
                alt="icon"/>{{ item.value }}
            </li>
          </ul>
        </div>
        <div class="socials" v-if="social.length > 0" style="display:flex; flex-direction:row">
          <div v-for="item in social" :key="item.id">
            <a :href="item.url" target="_blank">
              <img
                  style="height: 36px; width:36px;padding:2px" :src="imageUrl(item.icon)"
                  alt="icon"/>
              <!--            <i class="uil uil-twitter text-2xl"></i>-->
            </a>
          </div>
        </div>
      </div>

      <div class="footer-app" v-if="store.length > 0">
        <h3>Аппликейшн татах</h3>
        <div v-for="item in store" :key="item.id" style="margin-right: 5px;">
          <a :href="item.links" target="_blank" class="app-link">
            <img :src="imageUrl(item.thumb)"
                 alt="store" style=" object-fit:cover ">
          </a>
        </div>
      </div>

      <!--      <div class="footer-safe-logo">-->
      <!--        &lt;!&ndash;        <a href="https://www.kidsafeseal.com/aboutourprogram.html" target="_blank" class="kid-safe">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <img src="/img/kidsafe.png" alt="logo"/>&ndash;&gt;-->
      <!--        &lt;!&ndash;        </a>&ndash;&gt;-->
      <!--      </div>-->
    </div>
    <div class="footer-copyright">
      <p>Copyright © 2024 - All right reserved by Brickmedia LLC</p>
    </div>
  </footer>
</template>

<script>

import AppStore from "../../assets/appstore.png";
import PlayStore from "../../assets/playstore.png";
import axios from "axios";
import config from "../../store/_boot/config";
import {imageUrl} from "../../utils";

export default {
  data() {
    return {
      AppStore,
      PlayStore,
      contact: [],
      social: [],
      store: [],
      footer_image: '',
      data: {

        phone: '',
        email: '',
        facebook: '',
        youtube: '',
        twitter: '',
      }
    };
  },
  components: {},

  created() {
    this.getData();
    this.getSocial();
    this.getStore();
    this.getFooterImage();
  },

  methods: {
    imageUrl,
    getData() {
      axios.get(`${config.HOST}/api/footer/contact`).then(({data}) => {
        if (data.status) {
          this.contact = data.data;
        }
      })
    },
    getSocial() {
      axios.get(`${config.HOST}/api/footer/social`).then(({data}) => {
        if (data.status) {
          this.social = data.data;
        }
      })
    },
    getStore() {
      axios.get(`${config.HOST}/api/footer/get-store`).then(({data}) => {
        if (data.status) {
          this.store = data.data;
        }
      })
    },
    getFooterImage() {
      axios.get(`${config.HOST}/api/footer/get-footer-image`).then(({data}) => {
        if (data.status) {
          this.footer_image = data.data.image;
          console.log('this store-------->', this.footer_image);
        }
      })
    },
    openDefaultMailClient() {
      window.location.href = 'mailto:example@example.com'; // Change the email address as needed
    }


  }
};
</script>

<style>
.store-logo {
  height: 18px;
  margin-right: 5px;
}
</style>
export * from "./_actions/slide.action";
export * from "./_actions/media.action";
export * from "./_actions/episode.action";
export * from "./_actions/comment.action";
export * from "./_actions/content.action";
export * from "./_actions/payment.action";
export * from "./_actions/auth.action";
export * from "./_actions/notification.action";
export * from "./_actions/movie.action";

//General state actions
export const resetState = ({commit}) => {
    commit("resetState");
};

export const setBackLink = ({commit}, payload) => {
    commit("setBackLink", payload);
};

export const toggleSidebar = ({commit}, payload) => {
    commit("toggleSidebar", payload);
};
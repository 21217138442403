<template>
  <Header class="hidden lg:flex"/>
  <MobileHeader/>
  <div class="page-container min-h-screen seeroo-app">
    <router-view/>
  </div>
  <Footer/>
  <MobileMenu class="visible lg:invisible"/>
  <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut">
    <DetailsModal v-if="isModalActive"/>
  </transition>
</template>

<script>
import {toRefs, watch} from "vue";
import {useQueryProvider} from "vue-query";
import "animate.css";
import Header from "../components/Layout/Header.vue";
import MobileMenu from "../components/Layout/MobileMenu.vue";
import MobileHeader from "../components/Layout/MobileHeader.vue";
import Footer from "../components/Layout/Footer.vue";
import DetailsModal from "../components/Seeroo/DetailsModal.vue";
import {state} from "../store";
import {mapGetters} from 'vuex';

export default {
  components: {
    Header,
    MobileHeader,
    MobileMenu,
    Footer,
    DetailsModal,
  },
  setup() {
    useQueryProvider({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          refetchOnMount: false,
        },
      },
    });

    watch(
        () => state.isModalActive,
        () => {
          // setTimeout(function () {
          // window.scrollTo({
          //   left: 0,
          //   top: state.scrollTop,
          // });
          // }, 0);
        }
    );
    return {
      ...toRefs(state),
    };
  },

  data() {
    return {
      isMediaModal: true
    }
  },

  created() {
    setTimeout(() => {
      this.isMediaModal = true;
    })
  },

  computed: {
    ...mapGetters(['showMenu'])
  }
};
</script>

import axios from "axios";
import config from "./config";

const axiosConfig = {
    baseURL: config.HOST,
    timeout: 30000,
};
const api = axios.create(axiosConfig)

api.interceptors.request.use(
    async config => {
        config.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

        if(localStorage.getItem('token')){
            config.headers.Authorization = `${localStorage.getItem('token')}`;
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

export default api;
<template>
  <div class="page-news">
    <div class="page-header">
      <div class="header-layer">
        <div class="header-title">
          <h3>Мэдээ, мэдээллийн дэлгэрэнгүй</h3>
          <span class="divider"></span>
        </div>

        <div class="header-breadcrumb">
          <router-link to="/news">
            <span>Мэдээ, мэдээлэл</span>
            <i class="isax isax-arrow-right-4"></i>
          </router-link>

          <router-link to="" class="current">
            <span>Дэлгэрэнгүй</span>
          </router-link>
        </div>

      </div>

    </div>
    <div class="news-detail-section">
      <div class="news-detail-card">
        <div class="news-detail-title">
          <h1>{{ newsDetail.title }}</h1>
        </div>
        <p class="news-detail-date"><i class="isax isax-calendar-2"
                                       style="font-size: 20px; padding-right:10px"></i>
          {{ formatDate(newsDetail.created_at) }}</p>
        <div class="news-detail-img">
          <img :src="imageUrl(newsDetail.thumb)" :alt="img"
               class="img"/>
        </div>
        <p class="news-detail-description" v-html="newsDetail.body"></p>
      </div>
    </div>
  </div>
  <div>

  </div>
</template>

<script>
import axios from "axios";
import config from "@store/_boot/config";
import {imageUrl} from "@utils";
import moment from 'moment';


export default {
  data() {
    return {
      img: '',
      title: "",
      description: '',

      page: 1,
      newsDetail: []
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    imageUrl,
    getNews() {
      axios.get(`${config.HOST}/api/footer/get-news-detail/${this.$route.params.id}`).then(({data}) => {
        if (data.status) {
          this.newsDetail = data.data;
          console.log("newsDetail----->", this.newsDetail)
        }
      })
    },
  },

};
</script>

<style>
.news-detail-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: transparent;
  padding: 2rem;
  border-radius: 10px;


  .news-detail-card {
    border-radius: 12px;
    margin-right: 40px;
    width: 50%;
    height: auto;

    .img {
      width: 100%;

      .news-detail-img {
        height: 220px;
        width: 100%;
      }
    }

    .news-detail-title {
      h1 {
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 20px;
      }
    }

    .news-detail-date {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 18px;
      margin-bottom: 16px;
    }

    .news-detail-description {
      font-size: 16px;
      margin-top: 20px;
      text-align: justify;
    }
  }

}

@media only screen and (max-width: 1024px) {
  .news-detail-section {
    margin-left: 20px;

    .news-detail-card {
      border-radius: 12px;
      margin-right: 40px;
      width: 80%;
      height: auto;
    }
  }
}

@media only screen and (max-width: 860px) {
  .news-detail-section {
    margin-left: 20px;

    .news-detail-card {
      border-radius: 12px;
      margin-right: 40px;
      width: 80%;
      height: auto;
    }
  }
}
</style>

<template>
  <p-sidebar class="p-sidebar-close author-dashboard" position="right" @hide="handleSidebarCloseBtn">
    <ProfileSidebar v-model:visible="showProfile"/>
    <PinSidebar v-model:visible="showPin"/>
    <Toast/>
    <div class="rounded author-dashboard-header">
      <div class="cover">
        <img
            src="https://seeroo.mn/seeroo/img/pro-cover.png"
            class="w-full h-44 object-cover rounded"/>

        <div class="cover-layer"></div>
        <div class="flex justify-center -mt-14 avatar-wrapper">
          <Image
              v-if="user != null && user != undefined"
              :src="user.image"
              class="h-20 w-20 rounded-full object-cover"/>
        </div>
      </div>
      <div class="text-center px-3 pt-2 mt-6">
        <h3 class="text-white text-md text-bold user-name">{{ user.nickname }}</h3>
      </div>
      <div class="pt-3 auth-actions">
        <div class="id-wrap">
          <span><strong>Таны ID:</strong> {{ user.id }}</span>
          <a href="javascript:void(0)" @click="copyIdCode()" v-tooltip="'Хуулах'"><i class="isax isax-copy"></i></a>
          <input type="hidden" id="id-code" :value="user.id">
        </div>
        <!--        <Button label="Профайл" icon="pi pi-user"-->
        <!--                class="p-button-outlined p-button-secondary p-button-sm"/>-->
        <Button @click="logout" icon="pi pi-sign-out" rounded
                class="p-button-outlined p-button-secondary p-button-sm p-button-rounded"/>
      </div>
    </div>

    <div class="author-dashboard-body">
      <div class="info-row">
        <h3>Хэрэглэгчийн мэдээлэл</h3>
        <div class="grid grid-cols-1 gap-4">

          <div class="col info-box">
            <i class="isax isax-timer"></i>
            <div class="info-box-child">
              <strong>{{ user.days }} өдөр</strong>
              <h3>Үлдсэн хоног</h3>
            </div>
          </div>

          <!--            <div class="col info-box">-->
          <!--              <i class="isax isax-wallet-2"></i>-->
          <!--              <strong>{{ user.balance }}</strong>-->
          <!--              <h3>Хэтэвч</h3>-->
          <!--            </div>-->
        </div>
      </div>

      <div class="info-row">
        <h3>Хэрэглэгчийн цэс</h3>
        <ul>

          <li>
            <!--                          <router-link to="/profile/">-->
            <!--                            <span>Миний профайл</span>-->
            <!--                          </router-link>-->

            <router-link to="javascript:void(0)" @click="action(1)">
              <span>Миний профайл</span>
            </router-link>
          </li>

          <li>
            <!--              <router-link to="/profile/change-password/">-->
            <!--                <span>Пин солих</span>-->
            <!--              </router-link>-->
            <router-link to="javascript:void(0)" @click="action(2)">
              <span>Пин солих</span>
            </router-link>
          </li>

          <li>
            <a href="javascript:void(0)" @click="toggleNav('/payment')">
              <span>Эрх сунгах</span>
            </a>
          </li>

<!--          <li>-->
<!--            <a href="javascript:void(0)">-->
<!--              <span>Бүртгэлтэй төхөөрөмжүүд</span>-->
<!--            </a>-->
<!--          </li>-->

          <li>
            <router-link @click="show" to="/history">
              <span>Үзсэн түүх</span>
            </router-link>
          </li>

          <li>
            <router-link @click="show" to="/episode/liked">
<!--            <router-link to="/episode/new" @click="handleSidebarCloseBtn">-->
              <span>Таалагдсан контент</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="author-dashboard-footer">
      <p class="copyright">Copyright © 2024 All right reserved by Brickmedia LLC</p>
    </div>
  </p-sidebar>
</template>

<script>

import Sidebar from 'primevue/sidebar';
import Image from "@components/Image.vue";
import ProfileSidebar from "./../pages/profile/sidebar-profile.vue";
import PinSidebar from "./../pages/profile/sidebar-change-password.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AuthSidebar",
  props: ["show", 'hideUserMenu'],
  components: {
    "p-sidebar": Sidebar,
    Image,
    ProfileSidebar,
    PinSidebar,
  },
  data() {
    return {
      showProfile: false,
      showPin: false,
      showPayment: false,
      visibleUserMenu: false,
      value1: 'Off',
      value2: null,
      value3: null,
      listSelectOptions: [
        {name: 'Сүүлд үзсэн', value: 1},
        {name: 'Таалагдсан', value: 2},
        {name: 'Дараа үзэх', value: 3}
      ],
      messages: [],
      sidebar: false
    }
  },
  computed: {
    ...mapGetters(['isLoadingAuth', 'user'])
  },
  methods: {
    ...mapActions(['getUser', 'logout']),
    copyIdCode() {
      let idToCopy = document.querySelector('#id-code')
      idToCopy.setAttribute('type', 'text')
      idToCopy.select()

      try {
        var successful = document.execCommand('copy');
        if (successful) {
          this.$toast.success('Таны ID хаягыг амжилттай хууллаа.');
        } else {
          this.$toast.warning('Таны ID хаягыг хуулж чадсангүй');
        }
      } catch (err) {
        this.$toast.error('Уучлаарай, хуулах боломжгүй байна.');
      }

      /* unselect the range */
      idToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    action(id) {
      this.showProfile = false;
      this.showPin = false;
      this.showPayment = false;
      switch (id) {
        case 1:
          this.showProfile = true;
          break;
        case 2:
          this.showPin = true;
          break;
        case 3:
          this.showPayment = true;
          break;
      }
    },
    handleSidebarCloseBtn() {
      this.showProfile = false;
      this.showPin = false;
      this.showPayment = false;
      console.log('Sidebar closeddd');
    },

    toggleNav(link){
      this.hideUserMenu();
      this.$router.push(link);
      // this.$props.show = false;
    },
  }
}
</script>

<style lang="scss" scoped>
//.p-sidebar-mask{
//  z-index: 110!important;
//}
::v-deep(.p-scrollpanel) {
  p {
    padding: .5rem;
    line-height: 1.2;
    margin: 0;
  }

  &.ep-custombar-srcoll {
    width: 100%;
    height: 400px;

    .p-scrollpanel-bar {
      width: 4px;
    }
  }
}
</style>
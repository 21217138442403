<div {{ if options.mini }} class="max-player-mini" {{ /if }} {{ if !options.mini }} class="max-player" {{ /if }}>
    <div class="dplayer-mask"></div>
    <div class="dplayer-video-wrap" oncontextmenu="return false">
        <div class="max-player-video">
            <div class="max-player-video-inner">
                {{ include './video.art' video }}
                <div class="dplayer-subtitle"></div>
            </div>
        </div>

        {{ if options.logo }}
            <div class="dplayer-logo">
                <img src="{{ options.logo }}">
            </div>
        {{ /if }}

        {{ if options.stamp }}
            <div class="max-stamp" style="top: {{options.stamp.x}}%; left: {{options.stamp.y}}%; margin-right: 300px;">
                <span>{{ options.stamp.user }}</span>
            </div>
        {{ /if }}

        {{ if options.author }}
            <div class="m-author dplayer-author">
                <strong>Орчуулсан: {{ options.author }}</strong>
            </div>
        {{ /if }}

        <div class="dplayer-danmaku" {{ if options.danmaku && options.danmaku.bottm }} style="margin-bottom:{{ options.danmaku.bottm }}"{{ /if }}>
            <div class="dplayer-danmaku-item dplayer-danmaku-item--demo"></div>
        </div>

        <div class="dplayer-bezel">
            <span class="dplayer-bezel-icon"></span>
            {{ if options.danmaku }}
                <span class="dplayer-danloading">{{ tran('Danmaku is loadifng') }}</span>
            {{ /if }}
            <span class="diplayer-loading-icon">{{@ icons.loading }}</span>
        </div>
    </div>

    {{ if options.skipOp }}
    <div class="op-skip dplayer-skip">
        <strong>Эхлэл алгасах</strong>
    </div>
    {{ /if }}

    {{ if options.skipEd }}
    <div class="ed-skip dplayer-skip">
        <strong>Төгсгөл алгасах</strong>
    </div>
    {{ /if }}

    <div class="dplayer-controller-mask"></div>

    {{ if options.goBack }}
    <div class="dplayer-back">
        <span class="dplayer-icon-back">{{@ icons.back }}</span>
    </div>
    {{ /if }}

    <div class="max-title">
        {{ if options.title && options.title.main }}
        <span class="main-title">{{ options.title.main }}</span>
        {{ /if }}

        {{ if options.title && options.title.sub }}
        <span class="sub-title">{{ options.title.sub }}</span>
        {{ /if }}
    </div>

    <div class="dplayer-controller">
        <div class="dplayer-icons dplayer-comment-box">
            <button class="dplayer-icon dplayer-comment-setting-icon" data-balloon="Тохиргоо" data-balloon-pos="up">
                <span class="dplayer-icon-content">{{@ icons.pallette }}</span>
            </button>
            <div class="dplayer-comment-setting-box">
                <div class="dplayer-comment-setting-color">
                    <div class="dplayer-comment-setting-title">{{ tran('Set danmaku color') }}</div>
                    <label>
                        <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#fff" checked>
                        <span style="background: #fff;"></span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#e54256">
                        <span style="background: #e54256"></span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#ffe133">
                        <span style="background: #ffe133"></span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#64DD17">
                        <span style="background: #64DD17"></span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#39ccff">
                        <span style="background: #39ccff"></span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-color-{{ index }}" value="#D500F9">
                        <span style="background: #D500F9"></span>
                    </label>
                </div>
                <div class="dplayer-comment-setting-type">
                    <div class="dplayer-comment-setting-title">{{ tran('Set danmaku type') }}</div>
                    <label>
                        <input type="radio" name="dplayer-danmaku-type-{{ index }}" value="1">
                        <span>{{ tran('Top') }}</span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-type-{{ index }}" value="0" checked>
                        <span>{{ tran('Rolling') }}</span>
                    </label>
                    <label>
                        <input type="radio" name="dplayer-danmaku-type-{{ index }}" value="2">
                        <span>{{ tran('Bottom') }}</span>
                    </label>
                </div>
            </div>
            <input class="dplayer-comment-input" type="text" placeholder="{{ tran('Input danmaku, hit Enter') }}" maxlength="30">
            <button class="dplayer-icon dplayer-send-icon" data-balloon="{{ tran('Send') }}" data-balloon-pos="up">
                <span class="dplayer-icon-content">{{@ icons.send }}</span>
            </button>
        </div>

        <div class="max-actions">
            <div class="dplayer-icons dplayer-icons-left">
                <div class="dplayer-volume">
                    <button class="dplayer-icon dplayer-volume-icon" aria-label="Дуу хаах" data-balloon-pos="up">
                        <span class="dplayer-icon-content">{{@ icons.volumeDown }}</span>
                    </button>
                    <div class="dplayer-volume-bar-wrap" data-balloon-pos="up">
                        <div class="dplayer-volume-bar">
                            <div class="dplayer-volume-bar-inner" style="background: {{ options.theme }};">
                                <span class="dplayer-thumb" style="background: {{ options.theme }}"></span>
                            </div>
                        </div>
                    </div>
                </div>

                <span class="dplayer-time">
                    <span class="dplayer-ptime">0:00</span> /
                    <span class="dplayer-dtime">0:00</span>
                </span>

                {{ if options.live }}
                <span class="dplayer-live-badge"><span class="dplayer-live-dot" style="background: {{ options.theme }};"></span>{{ tran('Live') }}</span>
                {{ /if }}
            </div>

            <div class="dplayer-icons max-player-icons-center">
                <button class="dplayer-icon dplayer-backward-icon" aria-label="Ухраах" data-balloon-pos="up">
                    <span class="dplayer-icon-content">{{@ icons.backward }}</span>
                </button>

                {{ if options.prev }}
                    <div class="dplayer-icon dplayer-prev-icon" aria-label="Өмнөх анги" data-balloon-pos="up">
                        <span class="dplayer-icon-content">{{@ icons.prev }}</span>
                    </div>
                {{ /if }}

                {{ if !options.prev }}
                    <div class="dplayer-icon dplayer-prev-icon" style="opacity: .4;"  aria-label="Өмнөх анги" data-balloon-pos="up">
                        <span class="dplayer-icon-content">{{@ icons.prev }}</span>
                    </div>
                {{ /if }}

                <button class="dplayer-icon dplayer-play-icon">
                    <span class="dplayer-icon-content">{{@ icons.play }}</span>
                </button>

                {{ if options.next }}
                <div class="dplayer-icon dplayer-next-icon" aria-label="Дараагийн анги" data-balloon-pos="up">
                    <span class="dplayer-icon-content">{{@ icons.next }}</span>
                </div>
                {{ /if }}

                {{ if !options.next }}
                    <div class="dplayer-icon dplayer-next-icon" style="opacity: .4;">
                        <span class="dplayer-icon-content">{{@ icons.next }}</span>
                    </div>
                {{ /if }}

                <button class="dplayer-icon dplayer-forward-icon" aria-label="Гүйлгэх" data-balloon-pos="up">
                    <span class="dplayer-icon-content">{{@ icons.forward }}</span>
                </button>
            </div>

            <div class="dplayer-icons dplayer-icons-right">
                {{ if options.dubs }}
                    <div class="dplayer-sound">
                        <button class="dplayer-icon dplayer-sound-icon" aria-label="Аудио" data-balloon-pos="up">
                            <span class="dplayer-icon-content">{{@ icons.sound }}</span>
                        </button>

                        <div class="dplayer-sound-options">
                            <div class="dplayer-sound-panel">
                                <h3 class="panel-title">Дуу оруулалт</h3>
                            </div>

                            <div class="dplayer-sound-item" data-sound="0" data-key="off">
                                <span class="dplayer-label">Off</span>
                                <span class="max-dub-check" id="sound-check-off"></span>
                            </div>
                            {{ each options.dubs }}
                                <div class="dplayer-sound-item" data-sound="{{ $value.url }}" data-key="{{ $value.key }}">
                                    <span class="dplayer-label">{{ $value.label }}</span>
                                    <span class="max-dub-check" id="sound-check-{{$value.key}}"></span>
                                </div>
                            {{ /each }}
                        </div>
                    </div>
                {{ /if }}

                {{ if options.subs }}
                    <div class="dplayer-sub">
                        <button class="dplayer-icon dplayer-sub-icon" aria-label="Хадмал" data-balloon-pos="up">
                            <span class="dplayer-icon-content">{{@ icons.subtitle }}</span>
                        </button>

                        <div class="dplayer-sub-options">
                            <div class="dplayer-sub-panel">
                                <h3 class="panel-title">Хадмал орчуулга</h3>
                            </div>

                            {{ each options.subs }}
                                <div class="dplayer-sub-item" data-sub="{{ $value }}" data-key="{{ $value.code }}">
                                    <span class="dplayer-label">{{ $value.label }}</span>
                                    <span class="max-sub-check" id="sub-check-{{$value.code}}"></span>
                                </div>
                            {{ /each }}
                        </div>
                    </div>
                {{ /if }}

                <div class="dplayer-setting">
                    <button class="dplayer-icon dplayer-setting-icon" aria-label="Тохиргоо" data-balloon-pos="up">
                        <span class="dplayer-icon-content">{{@ icons.setting }}</span>
                    </button>

                    <div class="dplayer-setting-box">
                        <div class="dplayer-setting-origin-panel">
                            <h3 class="panel-title">Тохиргоо</h3>

                            <div class="dplayer-setting-item dplayer-setting-speed">
                                <span class="dplayer-icon-content">{{@ icons.speed }}</span>
                                <span class="dplayer-label">Тоглуулах хурд</span>
                                <span class="max-current-speed max-setting-meta">x1</span>
                                <div class="dplayer-toggle">{{@ icons.right }}</div>
                            </div>

                            {{ if options.video.quality }}
                            <div class="dplayer-setting-item dplayer-setting-quality">
                                <span class="dplayer-icon-content">{{@ icons.quality }}</span>
                                <span class="dplayer-label">Бичлэгийн чанар</span>
                                <span class="max-current-quality max-setting-meta">{{ options.video.quality[options.video.defaultQuality].name }}</span>
                                <div class="dplayer-toggle">{{@ icons.right }}</div>
                            </div>
                            {{ /if }}

                            <div class="dplayer-setting-item dplayer-setting-loop">
                                <span class="dplayer-label no-ml">Эхлэл төгсгөл алгасах</span>
                                    <div class="toggle-wrap">
                                        <div class='toggle-auto skip-switch'>
                                            <div class='toggle-text-off'>OFF</div>
                                            <div class='glow-comp'></div>
                                            <div class='toggle-button'></div>
                                            <div class='toggle-text-on'>ON</div>
                                        </div>
                                    </div>
                            </div>

                            <div class="dplayer-setting-item dplayer-setting-loop">
                                <span class="dplayer-label no-ml">Дараагийн анги тоглуулах</span>
                                    <div class="toggle-wrap">
                                        <div class='toggle-auto auto-play-switch'>
                                            <div class='toggle-text-off'>OFF</div>
                                            <div class='glow-comp'></div>
                                            <div class='toggle-button'></div>
                                            <div class='toggle-text-on'>ON</div>
                                        </div>
                                    </div>
                            </div>

                            <div class="dplayer-setting-item dplayer-setting-showdan">
                                <span class="dplayer-label">{{ tran('Show danmaku') }}</span>
                                <div class="dplayer-toggle">
                                    <input class="dplayer-showdan-setting-input" type="checkbox" name="dplayer-toggle-dan">
                                    <label for="dplayer-toggle-dan"></label>
                                </div>
                            </div>
                            <div class="dplayer-setting-item dplayer-setting-danunlimit">
                                <span class="dplayer-label">{{ tran('Unlimited danmaku') }}</span>
                                <div class="dplayer-toggle">
                                    <input class="dplayer-danunlimit-setting-input" type="checkbox" name="dplayer-toggle-danunlimit">
                                    <label for="dplayer-toggle-danunlimit"></label>
                                </div>
                            </div>
                            <div class="dplayer-setting-item dplayer-setting-danmaku">
                                <span class="dplayer-label">{{ tran('Opacity for danmaku') }}</span>
                                <div class="dplayer-danmaku-bar-wrap">
                                    <div class="dplayer-danmaku-bar">
                                        <div class="dplayer-danmaku-bar-inner">
                                            <span class="dplayer-thumb"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Speed settings panel -->
                        <div class="dplayer-setting-speed-panel">
                            <h3 class="panel-title">
                                <span class="dplayer-icon-content panel-back-btn">{{@ icons.back }}</span>
                                <span>Бичлэгийн хурд</span>
                            </h3>

                            {{ each options.playbackSpeed }}
                                <div class="dplayer-setting-speed-item" data-speed="{{ $value }}">
                                    <span class="dplayer-label">{{ $value === 1 ? tran('Normal') : $value }}</span>
                                    <span class="max-speed-check" id="speed-check-{{$value.toString().replace(".", "-")}}"></span>
                                </div>
                            {{ /each }}
                        </div>

                        <!-- Quality settings panel -->
                        {{ if options.video.quality }}
                            <div class="dplayer-setting-quality-panel">
                                <h3 class="panel-title">
                                    <span class="dplayer-icon-content panel-back-btn">{{@ icons.back }}</span>
                                    <span>Бичлэгийн чанар</span>
                                </h3>

                                <div class="dplayer-quality-list">
                                    {{ each options.video.quality }}
                                        <div class="dplayer-quality-item" data-index="{{ $index }}">
                                            {{ $value.name }}
                                            <span class="max-quality-check" id="quality-check-{{$index}}"></span>
                                        </div>
                                    {{ /each }}
                                </div>

<!--                                <div class="dplayer-quality-list">-->
<!--                                {{ each options.video.quality }}-->
<!--                                    <div class="dplayer-quality-item" data-index="{{ $index }}">-->
<!--                                        <span class="dplayer-label">{{ $value.name }}</span>-->
<!--                                    </div>-->
<!--                                {{ /each }}-->
<!--                                </div>-->
                            </div>
                        {{ /if }}
                    </div>
                </div>

                <div class="dplayer-full">
                    {{ if options.theatreMode }}
                    <button class="dplayer-icon dplayer-full-in-icon" aria-label="Театр горим" data-balloon-pos="up">
                        <span class="dplayer-icon-content">{{@ icons.fullWeb }}</span>
                    </button>
                    {{ /if }}

                    <button class="dplayer-icon dplayer-full-icon" aria-label="Бүтэн дэлгэц" data-balloon-pos="up">
                        <span class="dplayer-icon-content">{{@ icons.full }}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="max-bar">
            <div class="dplayer-bar-wrap">
                <div class="dplayer-bar-time hidden">00:00</div>
                <div class="dplayer-bar-preview"></div>
                <div class="dplayer-bar">
                    <div class="dplayer-loaded" style="width: 0;"></div>
                    <div class="dplayer-played" style="width: 0; background: {{ options.theme }}">
                        <span class="dplayer-thumb" style="background: {{ options.theme }}"></span>
                    </div>
                </div>
            </div>
<!--            <span class="dplayer-time">-->
<!--                <span class="dplayer-ptime">0:00</span> / <span class="dplayer-dtime">0:00</span>-->
<!--            </span>-->
        </div>
    </div>

    <div class="dplayer-info-panel dplayer-info-panel-hide">
        <div class="dplayer-info-panel-close">[x]</div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-version">
            <span class="dplayer-info-panel-item-title">Player version</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-fps">
            <span class="dplayer-info-panel-item-title">Player FPS</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-type">
            <span class="dplayer-info-panel-item-title">Video type</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-url">
            <span class="dplayer-info-panel-item-title">Video url</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-resolution">
            <span class="dplayer-info-panel-item-title">Video resolution</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-duration">
            <span class="dplayer-info-panel-item-title">Video duration</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        {{ if options.danmaku }}
        <div class="dplayer-info-panel-item dplayer-info-panel-item-danmaku-id">
            <span class="dplayer-info-panel-item-title">Danmaku id</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-danmaku-api">
            <span class="dplayer-info-panel-item-title">Danmaku api</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        <div class="dplayer-info-panel-item dplayer-info-panel-item-danmaku-amount">
            <span class="dplayer-info-panel-item-title">Danmaku amount</span>
            <span class="dplayer-info-panel-item-data"></span>
        </div>
        {{ /if }}
    </div>

    <div class="dplayer-menu">
        {{ each options.contextmenu }}
            <div class="dplayer-menu-item">
                <a{{ if $value.link }} target="_blank"{{ /if }} href="{{ $value.link || 'javascript:void(0);' }}">{{ tran($value.text) }}</a>
            </div>
        {{ /each }}
    </div>

    <div class="dplayer-notice"></div>
    <button class="dplayer-mobile-play">
        {{@ icons.play }}
    </button>
</div>

const aesjs = require('aes-js');
const flowtype = require('flowtype-js')
import Hashids from 'hashids';

const hashids = new Hashids('', 0, 'abcdefghijklmnopqrstuvwxyz')

class Subtitle {
    constructor(container, video, options, stamp, events) {
        this.container = container;
        this.video = video;
        this.options = options;
        this.events = events;
        this.stamp = stamp;
        this.init();
    }

    init() {
        this.container.style.fontSize = this.options.fontSize;
        this.container.style.bottom = this.options.bottom;
        this.container.style.color = this.options.color;

        flowtype(this.container, {
            minimum: 576,
            maximum: 1920,
            minFont: 18,
            maxFont: 44,
            fontRatio: 30
        });

        let encId = "";
        let randomRow = Math.floor(Math.random() * 100) + 10;
        let shouldAddStamp = false;
        let idAddStartTime = 0;

        if (typeof this.stamp !== "undefined" && this.stamp != null && this.stamp.user != null) {
            let cId = hashids.encodeHex(this.stamp.user.toString());
            encId = this.cEnc(cId, 16)
            shouldAddStamp = true;
            console.log(shouldAddStamp, idAddStartTime);
        }

        if (this.video.textTracks && this.video.textTracks[0]) {
            const track = this.video.textTracks[0];

            if (track) {
                setTimeout(() => {
                    if (track.cues && track.cues[randomRow]) {
                        let addCue = track.cues[randomRow];
                        idAddStartTime = addCue.startTime;
                    }
                }, 1000);
            }

            track.oncuechange = () => {
                const cue = track.activeCues[0];
                this.container.innerHTML = '';
                if (cue) {
                    const template = document.createElement('div');
                    template.appendChild(cue.getCueAsHTML());

                    if (this.options.isRaw) {
                        let trackHtml = template.innerHTML
                            .split(/\r?\n/)
                            .map((item) => `<p>${item}</p>`)
                            .join('');
                        this.container.innerHTML = `<p>${trackHtml}</p>`;
                    } else {
                        let trackHtml = template.innerHTML
                            .split(/\r?\n/)
                            .join('');

                        let decryptedCC = this.cDec(trackHtml);
                        if (shouldAddStamp && cue.startTime == idAddStartTime) {
                            decryptedCC = decryptedCC + "<br/> " + encId;
                        }
                        this.container.innerHTML = `<p>${decryptedCC}</p>`;
                    }
                }
                this.events.trigger('subtitle_change');
            };
        }
    }

    show() {
        this.container.classList.remove('dplayer-subtitle-hide');
        this.events.trigger('subtitle_show');
    }

    hide() {
        this.container.classList.add('dplayer-subtitle-hide');
        this.events.trigger('subtitle_hide');
    }

    toggle() {
        if (this.container.classList.contains('dplayer-subtitle-hide')) {
            this.show();
        } else {
            this.hide();
        }
    }

    cDec(encStr) {
        let encryptedBytes = aesjs.utils.hex.toBytes(encStr);
        let aesCbc = new aesjs.ModeOfOperation.ctr(this.options.key, this.options.iv);
        let decryptedBytes = aesCbc.decrypt(encryptedBytes);
        let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);
        decryptedText = decryptedText.replaceAll('***newline***', '<br/>');
        return decryptedText;
    }

    cEnc(plainString, shiftAmount) {
        let cipheredtext = "";
        for (let i = 0; i < plainString.length; i++) {
            let plainCharacter = plainString.charCodeAt(i);
            if (plainCharacter >= 97 && plainCharacter <= 122) {
                cipheredtext += String.fromCharCode((plainCharacter - 97 + shiftAmount) % 26 + 97);
            } else if (plainCharacter >= 65 && plainCharacter <= 90) {
                cipheredtext += String.fromCharCode((plainCharacter - 65 + shiftAmount) % 26 + 65);
            } else {
                cipheredtext += String.fromCharCode(plainCharacter);
            }
        }
        return cipheredtext;
    }
}

export default Subtitle;

export const setHomeSlide = (state, payload) => {
    state.homeSlide = payload;
};

export const setMediaSlide = (state, payload) => {
    state.mediaSlide = payload;
};

export const setMovieSlide = (state, payload) => {
    state.movieSlide = payload;
};

export const setTheatreSlide = (state, payload) => {
    state.theatreSlide = payload;
};

export const setPageSlide = (state, payload) => {
    state.pageSlide = payload;
};

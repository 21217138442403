// https://stackoverflow.com/questions/8495687/split-array-into-chunks
import config from "../store/_boot/config";

export const chunk = (array, chunkSize) => {
    const arr = [];
    for (let i = 0; i < array.length; i += chunkSize)
        arr.push(array.slice(i, i + chunkSize));
    return arr;
};

export const randomString = (length = 7) => {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, "");

    // Make the string lowercase
    str = str.toLowerCase();

    // Remove accents, swap ñ for n, etc
    const from =
        "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
    const to =
        "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
    }

    // Remove invalid chars
    str = str
        .replace(/[^a-z0-9 -]/g, "")
        // Collapse whitespace and replace by -
        .replace(/\s+/g, "-")
        // Collapse dashes
        .replace(/-+/g, "-");

    return str;
};

export const randomIndex = (length) => {
    return Math.floor(Math.random() * length);
};

// https://stackoverflow.com/questions/19269545/how-to-get-a-number-of-random-elements-from-an-array

export const randomElement = (array, length = 1) => {
    // Shuffle array
    const shuffled = array.sort(() => 0.5 - Math.random());

    // Get sub-array of first n elements after shuffled
    let selected = shuffled.slice(0, length);

    return selected;
};

// https://stackoverflow.com/questions/123999/how-can-i-tell-if-a-dom-element-is-visible-in-the-current-viewport

export const isElementInViewport = (el) => {
    const rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight ||
            document.documentElement.clientHeight) /* or $(window).height() */ &&
        rect.right <=
        (window.innerWidth ||
            document.documentElement.clientWidth) /* or $(window).width() */
    );
};


export const imageUrl = (url) => {
    if(url == null) return null;
    return config.IMG_HOST + url.toString().replaceAll(" ", "%20");
}

export const tr = ($v1, $v2) => {
    return window.init.app === 'animax' ? $v1 : $v2;
}
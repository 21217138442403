<template>
  <img :src="source" v-lazy="source"/>
</template>

<script>
import config from "../store/_boot/config";

export default {
  props: {
    src: {
      type: String,
    }
  },

  computed: {
    source() {
      return `${config.IMG_HOST}${this.src}`
    }
  }
};
</script>

<style>
img[lazy="loaded"] {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: fadeIn;
}
</style>
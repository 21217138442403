<template>
  <button
    @click="handleClick"
    class="flex items-center justify-center rounded-full p-1 border border-secondary hover:border-gray-300 w-6 h-6">
    <slot />
  </button>
</template>

<script>
export default {
  methods: {
    handleClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>
<template>
  <div class="grid grid-rows-1 grid-cols-2 gap-px-4 rounded bg-[#1f2839] text-gray-400">
    <div class="grid grid-rows-1 grid-flow-col gap-4 flex items-center justify-start bg-[#161f2d]">
      <div class="row-span-2">
        <Image
            :src="data.image"
            class="h-16 w-12 min-w-[30px] object-cover rounded"/>
      </div>
      <div class="col-span-1 text-sm">{{ data.name }}
      </div>
    </div>
    <div class="grid grid-rows-1 grid-flow-col gap-4 flex items-center justify-end">
      <div class="col-span-1 text-sm text-right">{{ data.vname }}</div>
      <div class="row-span-2">
        <Image
            :src="data.vimage"
            class="h-16 w-12 min-w-[30px] object-cover rounded"/>
      </div>
    </div>
  </div>
</template>

<script>
import Image from "./Image.vue";

export default {
  name: "AnimeActorCard",
  props: ["data"],
  components: {
    Image,
  },
};
</script>

<style scoped>

.desc-txt{
  font-size: 14px !important;
}

.video-card img {
  /*@apply object-cover mask mask-circle absolute top-0 left-0 w-full h-full;*/
}
</style>
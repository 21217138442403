<template>
  <Sidebar v-model:visible="visibleProfile" header="Edit Profile" class="profile-side" position="right" :modal="false" :draggable="false">
    <form class="card-body w-full" :model="ruleForm" :rules="rules" ref="ruleForm">
      <div class="md:flex md:items-center mb-6 p-6">
        <div class="md:w-1/3">
          <label class="block md:text-right mb-1 md:mb-0 pr-4">
            <span class="label-text">Зураг:</span>
          </label>
        </div>
        <div class="md:w-2/3">
            <div class="m-1 prime-upload">
              <div class="flex items-center justify-center md:w-3/4">
                <label
                    class="flex flex-col w-full h-32 border-2 border-dashed hover:bg-base-200 hover:border-accent-focus hover:bg-white-400">
                  <div class="flex flex-col items-center justify-center pt-7">
                    <svg v-if="ruleForm.image == ''" xmlns="http://www.w3.org/2000/svg"
                         class="w-12 h-12 text-white-400 group-hover:text-white-600"
                         viewBox="0 0 20 20"
                         fill="currentColor">
                      <path fill-rule="evenodd"
                            d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z"
                            clip-rule="evenodd"/>
                    </svg>

                    <img v-else :src="ruleForm.image" alt="image">

                    <p class="pt-1 text-sm tracking-wider text-white-400 group-hover:text-gray-600">
                        Зургаа сонгоно уу.
                    </p>
                  </div>
<!--                    <FileUpload mode="basic" url="/lambda/krud/upload" @upload="uploadImage($event)" accept="image/*" :maxFileSize="1000000"/>-->
                  <form method="post" action="/lambda/krud/upload">
                    <input type="file" name="file" :model="ruleForm.image" @change="uploadImage($event)" class="opacity-0" accept="image/*"/>
                  </form>
                </label>
              </div>
<!--              <FileUpload name="demo[]" url="/lambda/krud/upload" @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000">-->
<!--                <template #empty>-->
<!--                  <p>Upload-->
<!--                    Image(jpg,png,svg,jpeg)</p>-->
<!--                </template>-->
<!--              </FileUpload>-->
            </div>
        </div>
      </div>
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block md:text-right mb-1 md:mb-0 pr-4">
            <span class="label-text">Нэр:</span>
          </label>
        </div>
        <div class="md:w-2/3">
          <InputText class="seeroo-input" v-model="ruleForm.firstname" invalid placeholder="Таны нэр"/>
<!--          <input type="text" placeholder="Таны нэр"-->
<!--                 class="input input-bordered hover:border-accent w-full md:w-3/4" v-model="ruleForm.firstname">-->
        </div>
      </div>
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block md:text-right mb-1 md:mb-0 pr-4">
            <span class="label-text">Овог:</span>
          </label>
        </div>
        <div class="md:w-2/3">
          <InputText class="seeroo-input" v-model="ruleForm.lastname" invalid placeholder="Таны овог"/>
<!--          <br>-->
<!--          <small id="username-help">Enter your username to reset your password.</small>-->
<!--          <input type="text" placeholder="Таны овог"-->
<!--                 class="input input-bordered hover:border-accent w-full md:w-3/4" v-model="ruleForm.lastname">-->
        </div>
      </div>
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block md:text-right mb-1 md:mb-0 pr-4">
            <span class="label-text">И-мэйл:</span>
          </label>
        </div>
        <div class="md:w-2/3">
          <InputText class="seeroo-input" v-model="ruleForm.nickename" invalid placeholder="И-мэйл хаяг"/>
<!--          <input type="text" placeholder="И-мэйл хаяг"-->
<!--                 class="input input-bordered hover:border-accent w-full md:w-3/4" v-model="ruleForm.nickename">-->
        </div>
      </div>
      <div class="md:flex md:items-center mb-6">
        <div class="md:w-1/3">
          <label class="block md:text-right mb-1 md:mb-0 pr-4">
            <span class="label-text">Хүйс:</span>
          </label>
        </div>
        <div v-for="gender in genders" :key="gender.key" class="flex align-items-center">
          <RadioButton class="seeroo-radio" v-model="ruleForm.gender" :inputId="gender.id" name="dynamic" :value="gender.id" />
          <label :for="gender.id" class="ml-2 mr-4">{{ gender.name }}</label>
        </div>
<!--        <div class="md:w-2/3">-->
<!--          <div class="w-1/2 flex gap-4 w-full md:w-3/4">-->
<!--            <label class="cursor-pointer label basis-1/2">-->
<!--              <input type="radio" name="gender" checked="checked"-->
<!--                     class="radio radio-accent radio-xs" value="" v-model="ruleForm.gender">-->
<!--              <span class="label-text pl-2">Эрэгтэй</span>-->
<!--            </label>-->
<!--            <label class="cursor-pointer label basis-1/2">-->
<!--              <input type="radio" name="gender" class="radio radio-accent radio-xs"-->
<!--                     value="" v-model="ruleForm.gender">-->
<!--              <span class="label-text pl-2">Эмэгтэй</span>-->
<!--            </label>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="md:flex md:items-center">
        <div class="md:w-1/3"></div>
        <div class="w-full md:w-2/3 mt-5">
          <Button label="Хадгалах" class="p-button-sm text-white" severity="success" @click="submitForm('ruleForm')"/>
          <Button label="Болих" class="p-button-sm ml-5" @click="resetForm"/>
          <!--            <button class="btn btn-accent btn-sm" @click="submitForm('ruleForm')" role="button">Хадгалах</button>-->
          <!--            <button class="btn btn-accent btn-sm btn-outline ml-3" @click="resetForm('ruleForm')" role="button">Болих</button>-->
        </div>
      </div>
    </form>
  </Sidebar>

</template>

<script>

import Sidebar from 'primevue/sidebar';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
// import axios from "axios";
// import FileUpload from 'primevue/fileupload';

export default {
  name: "ProfileSidebar",
  components: {
    Sidebar,
    InputText,
    RadioButton,
    Button,
    // FileUpload
  },
  data() {
    return {
      visibleProfile: false,
      ruleForm: {
        coverImage: '',
        image: '',
        firstname: '',
        lastname: '',
        nickename: '',
        gender: '',
        dialogImageUrl: '',
        dialogVisible: false
      },
      genders: [
        {
          id: 1,
          name: 'Эрэгтэй'
        },
        {
          id: 2,
          name: 'Эмэгтэй'
        },
        {
          id: 3,
          name: 'Бусад'
        }
      ],
      rules: {
        firstname: [
          {required: true, message: 'Нэрээ оруулна уу!', trigger: 'blur'},
          {min: 3, max: 5, message: 'Таны нэр 3-5 тэмдэгт орсон байх ёстой.', trigger: 'blur'}
        ],
        lastname: [
          {required: true, message: 'Овгоо оруулна уу!', trigger: 'blur'},
          {min: 3, max: 5, message: 'Таны овог 3-5 тэмдэгт орсон байх ёстой.', trigger: 'blur'}
        ],
        email: [
          {required: true, message: 'Имэйлээ оруулна уу!', trigger: 'blur'},
          {min: 3, max: 5, message: 'Таны оруулсан утга имэйл төрлийнх орсон байх ёстой.', trigger: 'blur'}
        ],
        gender: [
          {required: true, message: 'Хүйсээ сонгоно уу!', trigger: 'change'}
        ]
      }
    }
  },
  computed: {
  },
  methods: {
    uploadImage(e) {
      console.log('image');
      const file = e.target.files[0];
      this.ruleForm.image = file.name;
      console.log(file);
      console.log(this.ruleForm);
    },
    submitForm() {
      // console.log('thtere');
      // console.log(this.$refs['ruleForm']);
      // console.log(this.ruleForm);
      // axios.post(`${this.baseUrl}/api/m/update/profile/${this.userId}`, this.ruleForm).then(({data}) => {
      //   if (data.status) {
      //     // this.$notify.success({
      //     //   title: 'Амжилттай',
      //     //   message: 'Таны nickname амжилттай бүртгэгдлээ.'
      //     // });
      //   } else {
      //     // this.$notify.error({
      //     //   title: 'Nickname бүртгэх боломжгүй байна',
      //     //   message: 'Таны сонгосон nickname давхцаж байгаа тул өөр нэр сонгоод дахин илгээнэ үү.'
      //     // });
      //   }
      //
      //   this.isLoadingNickname = false;
      // })
      // this.$refs['ruleForm'].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
    resetForm() {
      this.ruleForm = {
        coverImage: '',
        image: '',
        firstname: '',
        lastname: '',
        gender: '',
        dialogImageUrl: '',
        dialogVisible: false
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    closePage() {
      this.visibleProfile = false;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
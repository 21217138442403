import api from "../_boot/baseApi";

export const getComments = ({commit, state}, payload) => {
    api.get(`/api/m/comments/ep/${state.episode.id}/${state.user != null ? state.user.id : ""}?page=${payload.vm.page}`)
        .then(({data}) => {
            if (payload.vm.page > 1) {
                let copyList = [...state.comments];
                data.data.forEach((item) => {
                    copyList.push(item);
                });
                commit("setComments", copyList);
            } else {
                commit("setComments", data.data);
            }
            commit('setCommentCount', data.total);

            setTimeout(() => {
                payload.vm.isLoading = false;
                payload.vm.isLoadingMore = false;
            }, 1000);

            if (data.current_page == data.last_page) {
                payload.vm.hasMore = false;
            } else {
                payload.vm.hasMore = true;
                payload.vm.page = data.current_page + 1;
            }

        }).catch(e => {
        console.log(e);
        payload.vm.isLoading = false;
        payload.vm.isLoadingMore = false;
    });

};

export const addComment = ({commit, state}, payload) => {
    // commit("addComment", payload);
    payload.vm.commentItem.parent_id = state.episode.id;
    payload.vm.commentItem.user_id = state.user.id;

    if (payload.vm.commentItem.body.length > 0) {
        payload.vm.senderLoading = true;
        api
            .post("/api/m/comment/post", payload.vm.commentItem)
            .then(({data}) => {
                if (data.status) {
                    setTimeout(() => {
                        payload.vm.commentItem.body = null;
                        // payload.vm.comments.unshift(data.comment);
                        commit('addComment', data.comment);
                        payload.vm.senderLoading = false;
                    }, 1000);

                    payload.vm.$toast.success("Сэтгэгдэл илгээгдлээ!");
                }
            })
            .catch(() => {
                setTimeout(() => {
                    payload.vm.senderLoading = false;
                    payload.vm.$toast.success("Сэтгэгдэл илгээх үед алдаа гарлаа!");
                }, 1000);
            });
    } else {
        // this.$message({
        //     message: "Сэтгэгдэлээ бичиж илгээнэ үү!",
        //     type: "error",
        // });
    }
};

export const setAction = ({commit, state}, payload) => {
    if (payload.action == 0) {
        if (payload.item.action == 1) {
            commit('decComLike', payload.item)
        }

        if (payload.item.action == 2) {
            commit('decComDislike', payload.item);
        }
    }

    if (payload.action == 1) {
        commit('incComLike', payload.item);
        if (payload.item.action == 2) {
            commit('decComDislike', payload.item);
        }
    }

    if (payload.action == 2) {
        commit('incComDislike', payload.item);
        if (payload.item.action == 1) {
            commit('decComLike', payload.item)
        }
    }

    commit('setComStatus', payload);

    api.get(`/api/m/comment/action/${payload.item.id}/${state.user.id}/${payload.action}`)
        .then(() => {
            // commit('setComAction', payload);
        });
}

export const deleteComment = () => {

};
export const setLatestEpisodes = (state, payload) => {
    state.latestEpisodes = payload;
    state.isLoadingLatestEps = false;
};

export const setHistoryEpisodes = (state, payload) => {
    state.historyEpisodes = payload;
};

export const setLatestHistory = (state, payload) => {
    state.latestHistoryEpisodes = payload;
};

export const setEpisodes = (state, payload) => {
    state.episodes = payload;
    state.allEpisodes = payload;
};

export const setGroups = (state, payload) => {
    state.groups = payload;
};

export const setGroupEpisodes = (state, payload) => {
    state.groups = state.groups.map((item) => {
        if (item.id == payload.group) {
            item.episodes = payload.episodes;
        }
        return item;
    });
};

export const searchEpisode = (state, payload) => {
    console.log(payload);
    state.episodes = state.allEpisodes;
    state.episodes = state.episodes.filter((ep) =>
        ep.number
            .toString()
            .toLowerCase()
            .includes(payload.toString().toLowerCase())
    );
};

export const sortEpisode = (state, payload) => {
    state.sortDirection = payload;
    state.allEpisodes = state.allEpisodes.slice().reverse();
    state.episodes = state.episodes.slice().reverse();
};

export const setEpisode = (state, payload) => {
    state.episode = payload;
};

export const setCanWatchStatus = (state, payload) => {
    state.canWatch = payload;
};

export const setLoadingEp = (state, payload) => {
    state.isLoadingEpisode = payload;
}

export const clearEpisode = (state) => {
    state.episodes = [];
    state.allEpisodes = [];
    state.isLoadingEpisode = false;
    state.comments = [];
    state.commentCount = 0;
    state.media = null;
    state.episode = null;
}

export const switchEpisode = (state) => {
    state.comments = [];
    state.commentCount = 0;
    state.episode = null;
}

export const setEpStatus = (state, payload) => {
    state.episode.liked = payload;
}

export const incEpDislike = (state) => {
    state.episode.dislikeCount++;
}

export const incEpLike = (state) => {
    state.episode.likeCount++;
}

export const decEpLike = (state) => {
    state.episode.likeCount--;
}

export const decEpDislike = (state) => {
    state.episode.dislikeCount--;
}
<template>
  <div class="w-full group cursor-pointer">
    <div class="relative">
      <div class="relative pt-[56.25%]" @click="gotoDetail(data.id)">
        <Image
            :src="data.image1"
            :alt="data.title"
            class="poster-card w-full h-full absolute left-0 top-0 rounded-md"
        />
      </div>

      <div
          @click="gotoDetail(data.id)"
          class="
          invisible
          opacity-0
          transition-all
          duration-300
          group-hover:opacity-100 group-hover:visible
          absolute
          left-1/2
          top-1/2
          -translate-x-1/2 -translate-y-1/2
          rounded-full
          p-4
          bg-white bg-opacity-80
          border border-primary
        "
      >
        <IconPlayFill/>
      </div>
    </div>

    <div class="p-4 h-60 w-full bg-[#fff] space-y-2 rounded-b-md">
      <div class="flex items-center justify-between">
        <div>
          <p class="text-lg font-netflix_medium line-clamp-1" @click="gotoDetail(data.id)">
            {{ data.title }}
          </p>

          <div class="flex space-x-2">
            <div class="flex items-center text-yellow-500">
              <IconStar/>
              <p>{{ data.rating ? data.rating : '0' }}/10</p>
            </div>

            <p>{{ data.release_date }}</p>
          </div>
        </div>
      </div>

      <p class="text-sm text-gray-400 line-clamp-6 text-justify txt-desc-sm" v-html="data.description"></p>
    </div>
  </div>
</template>

<script>
import IconPlayFill from "~icons/ph/play-fill";
import IconStar from "~icons/ic/sharp-star-purple500";
import Image from "./Image.vue";

export default {
  components: {Image, IconPlayFill, IconStar},
  props: ["data"],
  methods: {
    gotoDetail(id) {
      this.$router.push(`/theatre/detail/${id}`);
    }
  }
};
</script>
<template>
  <div
      v-if="type === 'element'"
      :class="[
      !layout.isContainer && 'animate-pulse bg-gray bg-opacity-20',
      layout.class,
    ]"
  >
    <SkeletonItem
        v-for="(childItem, index) in layout.children"
        :layout="childItem"
        :key="index"
    />
  </div>

  <SkeletonItem
      v-else-if="type === 'fragment'"
      v-for="(childItem, index) in layout.children"
      :layout="childItem"
      :key="index"
  />

  <component v-else :is="layout.type" v-bind="layout.props"></component>
</template>

<script>
// import {computed} from "vue";

export default {
  props: ["layout"],
  // setup({layout}) {
  //   const type = computed(() => {
  //     if (layout.type === "div") {
  //       return "element";
  //     }
  //
  //     if (typeof layout.type === "symbol") {
  //       return "fragment";
  //     }
  //
  //     return "component";
  //   });
  //
  //   return {
  //     type,
  //   };
  // },
  computed: {
    type() {
      if (this.layout.type === "div") {
        return "element";
      }

      if (typeof this.layout.type === "symbol") {
        return "fragment";
      }

      return "component";
    }
  }
};
</script>

<style>
</style>
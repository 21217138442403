import api from "../_boot/baseApi";
import _ from "lodash";

export const getMovies = ({commit, state}, payload) => {
    if (payload.isMore) {
        payload.vm.isLoadingMore = true;
    } else {
        payload.vm.isLoading = true;
    }
    api.post(`/api/m/movies/web?page=${payload.page}`, {
        cats: payload.cat == 'all' ? null : payload.cat,
        sort: payload.sort == 'all' ? null : payload.sort,
    }).then(({data}) => {
        if (payload.page == 1) {
            commit("setMovies", data.data);
        } else {
            let movies = _.clone(state.movies);
            data.data.forEach((item) => {
                movies.push(item);
            });
            commit("setMovies", movies);
        }

        payload.vm.page = data.current_page + 1;
        payload.vm.isLoading = false;
        payload.vm.isLoadingMore = false;

        if (data.current_page == data.last_page) {
            payload.vm.hasMore = false;
        } else {
            payload.vm.hasMore = true;
        }

    })
        .catch((e) => {
            console.log(e);
            payload.vm.isLoading = false;
            payload.vm.isLoadingMore = false;
        });
};

export const getMovie = ({commit}, payload) => {
    payload.vm.loading = true;
    api.get(`/api/m/movie/${payload.id}`).then(({data}) => {
        commit('setMovie', data)
        payload.vm.checkPermission(payload.id);
    }).catch(e => {
        console.log(e);
        payload.vm.loading = false;
    })
}
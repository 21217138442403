export * from "./_mutations/slide.mutation";
export * from "./_mutations/media.mutation";
export * from "./_mutations/episode.mutation";
export * from "./_mutations/comment.mutation";
export * from "./_mutations/content.mutation";
export * from "./_mutations/payment.mutation";
export * from "./_mutations/auth.mutation";
export * from "./_mutations/notification.mutation";
export * from "./_mutations/movie.mutation";

export const resetState = (state) => {
  state.anime = null;
  state.episode = null;
  state.episodes = [];
  state.allEpisodes = [];
  state.comments = [];
};

export const player = (state, payload) => {
  state.player = payload;
};

export const setBackLink = (state, payload) => {
    state.backLink = payload;
};

export const setWarningMsg = (state, payload) => {
  state.warnMsg = payload;
};

export const toggleSidebar = (state) => {
  state.showMenu = !state.showMenu;
};
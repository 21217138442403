import Subtitle from "./subtitle";

class Subctrl {
    constructor(player) {
        this.player = player;
        this.player.template.mask.addEventListener('click', () => {
            this.hide();
        });

        if (this.player.template.subBtn) {
            this.player.template.subBtn.addEventListener('click', () => {
                this.show();
            });
        }

        for (let i = 0; i < this.player.template.subItem.length; i++) {
            this.player.template.subItem[i].addEventListener('click', () => {
                this.checkActiveSub(this.player.template.subItem[i].dataset.sub);
            });
        }

        if (localStorage.getItem('sub')) {
            let checkEl = document.querySelector(`#sub-check-${localStorage.getItem('sub').toString()}`);
            if (checkEl) {
                checkEl.style.display = "inline-flex";
            }
        }
    }

    checkActiveSub(value) {
        if (value == 'off') {
            localStorage.setItem('sub', 'off');
            this.player.options.subtitle = null;
            document.getElementById("subTrack").setAttribute("src", '');
            return false;
        }

        let sub = JSON.parse(value);
        let els = document.querySelectorAll('.max-sub-check');
        els.forEach(function (el) {
            el.style.display = "none";
        });
        let checkEl = document.querySelector(`#sub-check-${sub.code.toString()}`);
        if (checkEl) {
            checkEl.style.display = "inline-flex";
        }

        localStorage.setItem('sub', sub.code);
        this.player.options.subtitle = sub;
        let subEl =  document.getElementById("subTrack");
        if(subEl) {
            subEl.setAttribute("src", sub.url);
        }

        this.subtitle = new Subtitle(this.player.template.subtitle, this.player.video, this.player.options.subtitle, this.player.options.stamp, this.player.events);
    }

    hide() {
        this.player.template.subOptions.classList.remove('dplayer-sub-options-open');
        this.player.template.mask.classList.remove('dplayer-mask-show');
        this.player.controller.disableAutoHide = false;
    }

    show() {
        this.player.template.subOptions.classList.add('dplayer-sub-options-open');
        this.player.template.mask.classList.add('dplayer-mask-show');
        this.player.controller.disableAutoHide = true;
    }
}

export default Subctrl;

export const setComments = (state, payload) => {
    state.comments = payload;
};

export const setCommentCount = (state, payload) => {
    state.commentCount = payload;
};

export const addComment = (state, payload) => {
    console.log("adding mutation");
    state.comments.unshift(payload);
};

export const deleteComment = (state, payload) => {
    state.comments = payload;
};

export const setComStatus = (state, payload) => {
    const index = state.comments.findIndex(item => item.id === payload.item.id);
    state.comments[index].action = payload.action;
}

export const incComDislike = (state, payload) => {
    const index = state.comments.findIndex(item => item.id === payload.id);
    state.comments[index].dislikes++;
}

export const incComLike = (state, payload) => {
    const index = state.comments.findIndex(item => item.id === payload.id);
    state.comments[index].likes++;
}

export const decComLike = (state, payload) => {
    const index = state.comments.findIndex(item => item.id === payload.id);
    state.comments[index].likes--;
}

export const decComDislike = (state, payload) => {
    const index = state.comments.findIndex(item => item.id === payload.id);
    state.comments[index].dislikes--;
}
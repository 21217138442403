class Sound {
    constructor(player) {
        this.player = player;
        this.player.template.mask.addEventListener('click', () => {
            this.hide();
        });

        if(this.player.template.soundBtn) {
            this.player.template.soundBtn.addEventListener('click', () => {
                this.show();
            });
        }

        for (let i = 0; i < this.player.template.soundItem.length; i++) {
            this.player.template.soundItem[i].addEventListener('click', () => {
                this.switchAudio(this.player.template.soundItem[i].dataset.sound);
                this.checkActiveDub(this.player.template.soundItem[i].dataset.key);
            });
        }
        if (localStorage.getItem('sound')) {
            this.checkActiveDub(localStorage.getItem('sound'));
        }
    }

    checkActiveDub(value) {
        let els = document.querySelectorAll('.max-dub-check');
        els.forEach(function (el) {
            el.style.display = "none";
        });
        let checkEl = document.querySelector(`#sound-check-${value.toString()}`);
        if (checkEl) {
            checkEl.style.display = "inline-flex";
        }
        localStorage.setItem('sound', value);
    }

    hide() {
        this.player.template.soundOptions.classList.remove('dplayer-sound-options-open');
        this.player.template.mask.classList.remove('dplayer-mask-show');
        this.player.controller.disableAutoHide = false;
    }

    show() {
        this.player.template.soundOptions.classList.add('dplayer-sound-options-open');
        this.player.template.mask.classList.add('dplayer-mask-show');
        this.player.controller.disableAutoHide = true;
    }

    switchAudio(value){
        this.audioPause();
        this.player.video.pause();

        this.player.options.audio = value;
        this.player.template.audio.src = value;
        this.player.template.audio.load();
        this.audioPlay();
        this.player.play();
        this.player.seek(this.player.video.currentTime);
        this.seekAudio(this.player.video.currentTime);
    }

    isPlayingAudio(audelem) {
        return !audelem.paused;
    }

    audioPlay() {
        if (this.player.options.audio && this.player.template.audio) {
            this.player.template.audio.play();
        }
    }

    audioPause() {
        if (this.player.options.audio && this.player.template.audio) {
            this.player.template.audio.pause();
        }
    }

    seekAudio(time) {
        if (this.player.options.audio && this.player.template.audio) {
            this.player.template.audio.currentTime = time;
        }
    }

    setAudioVolume(val) {
        if (this.player.options.audio && this.player.template.audio) {
            this.player.template.audio.volume = val;
        }
    }

    muteAudio(val) {
        if (this.player.options.audio && this.player.template.audio) {
            this.player.template.audio.muted = val;
        }
    }

    audioPlaybackSpeed(rate) {
        if (this.player.options.audio && this.player.template.audio) {
            this.player.template.audio.playbackRate = rate;
        }
    }
}

export default Sound;

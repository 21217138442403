<template>
  <div class="mobilemenu">
    <ul class="sidemenu">
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Нүүр'">
        <router-link to="/">
          <img src="/img/menu-icon/active/homepage.png" alt="logo" class="header-icon active"/>
          <img src="/img/menu-icon/inactive/homepage.png" alt="logo" class="header-icon inactive"/>
          <span>Нүүр</span>
        </router-link>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Жанр'">
        <router-link to="/series">
          <img src="/img/menu-icon/active/genre.png" alt="logo" class="header-icon active"/>
          <img src="/img/menu-icon/inactive/genre.png" alt="logo" class="header-icon inactive"/>
          <span>Жанр</span>
        </router-link>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Дүр'">
        <router-link to="/character">
          <img src="/img/menu-icon/active/character.png" alt="logo" class="header-icon active"/>
          <img src="/img/menu-icon/inactive/character.png" alt="logo" class="header-icon inactive"/>
          <span>Дүр</span>
        </router-link>
      </li>
      <li :class="[showMenu ? 'large-sidemenu' : 'small-sidemenu']" v-tooltip.top="'Нас'">
        <router-link to="/age">
          <img src="/img/menu-icon/active/age.png" alt="logo" class="header-icon active"/>
          <img src="/img/menu-icon/inactive/age.png" alt="logo" class="header-icon inactive"/>
          <span>Нас</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import useHeaderRoute from "@hooks/useHeaderRoute";
import {mapGetters} from 'vuex';
import Logo from "../../assets/logo.png";
import LogoMini from "../../assets/logo.png";

export default {
  setup() {
    const {currentRoute, routes} = useHeaderRoute();

    return {
      currentRoute,
      routes,
    };
  },
  data() {
    return {
      Logo,
      LogoMini
    }
  },
  computed: {
    ...mapGetters(['showMenu'])
  }
};
</script>
